import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Segment, Message, Icon } from 'semantic-ui-react';
import { parseISO } from 'date-fns';
import { connect } from 'react-redux';
import { useTracking } from '../../../Context';
import { useIsPreview } from '../../../config/preview.context';
import { appointmentProptypes, timeSlotObjectProptypes, userProptypes } from '../../../propTypes';
import { getString } from '../../../utils';
import './BookAppointmentBlock.scss';

import { localeFormat } from '../../../utils/date';
import { sweetAlert } from '../../../utils/popupUtils';
import { AppointmentAddToCalendarBlock } from '../../../workshops/blocks/WorkshopAddToCalendarBlock';
import BookingModal from './BookingModal';
import AccessMeetingRoomBlock from './AccessMeetingRoomBlock';
import { cancelAppointment } from './utils';
import { userAgenda } from '../../../agenda/store/agenda.selectors';

const BookAppointmentBlock = ({ item, timeSlots, translationPrefix, appointment, agenda }) => {
  const isPreview = useIsPreview();
  const { trackEvent } = useTracking;
  const [showSlots, setShowSlots] = useState(false);

  function closeModal() {
    setShowSlots(false);
  }

  function handleShowSlots() {
    if (isPreview) {
      sweetAlert({ icon: 'error', title: 'Booking disabled in preview mode' });
    } else {
      setShowSlots(true);
    }
  }

  async function cancel() {
    cancelAppointment(appointment, item, { trackEvent });
  }

  if (appointment) {
    const { startDate } = appointment;
    const date = parseISO(startDate);
    return (
      <div>
        <Segment className="BookAppointmentBlock--container">
          <Message>
            <Message.Header style={{ fontSize: 14 }}>
              <p>
                <Icon name="calendar outline alternate" /> {localeFormat(date, 'PPPP')}
              </p>
              <p>
                <Icon name="clock" /> {localeFormat(date, 'p')}
              </p>
            </Message.Header>
            <p>{getString(`${translationPrefix}.already-registered`)}</p>
            <AppointmentAddToCalendarBlock appointment={appointment} sponsor={item} primary />
            <Button
              className="BookAppointmentBlock--container__button"
              color="red"
              onClick={cancel}
            >
              {getString(`${translationPrefix}.cancel-appointment`)}
            </Button>
          </Message>
        </Segment>
        <AccessMeetingRoomBlock appointment={appointment} translationPrefix={translationPrefix} />
      </div>
    );
  }
  let styleDisabled = {};
  let bookMeetingLabel = getString(`${translationPrefix}.interested-by-sponsor`);
  if (!timeSlots.isBookingAvailable) {
    bookMeetingLabel = getString(`${translationPrefix}.unavailable-booking`);
    styleDisabled = { backgroundColor: 'grey' };
  }
  return (
    <>
      <Segment className="BookAppointmentBlock--container">
        <Message className="BookAppointmentBlock--container__message">
          <Message.Header>{bookMeetingLabel}</Message.Header>
          <p />
        </Message>
        <Button
          className="BookAppointmentBlock--container__button"
          primary
          disabled={!timeSlots.isBookingAvailable}
          style={styleDisabled}
          onClick={handleShowSlots}
        >
          {getString(`${translationPrefix}.book-a-meeting`)}
        </Button>
      </Segment>
      {showSlots && (
        <BookingModal item={item} onClose={closeModal} timeSlots={timeSlots} agenda={agenda} />
      )}
    </>
  );
};

BookAppointmentBlock.defaultProps = {
  appointment: undefined,
  timeSlots: {
    isBookingAvailable: false,
    freeTimeSlot: [],
  },
  translationPrefix: 'sponsors.sponsor',
};

BookAppointmentBlock.propTypes = {
  agenda: PropTypes.arrayOf(PropTypes.object).isRequired,
  appointment: PropTypes.shape(appointmentProptypes),
  item: PropTypes.shape(userProptypes).isRequired,
  timeSlots: timeSlotObjectProptypes,
  translationPrefix: PropTypes.string,
};

export default connect((state, props) => ({
  appointment: state.appointments.appointments.find(
    (appointment) => appointment.groupId === props.item._id,
  ),
  timeSlots: state.timeSlots.timeSlots[props.item._id],
  agenda: userAgenda(state),
}))(BookAppointmentBlock);
