/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo, useEffect } from 'react';
import { useSetState } from 'ahooks';
import { BlockProps } from '../types';
import store from '../../../shared/Store';
import platformService from '../../../core/services/platform.service';
import { useFreshAgendaWorkshops } from '../../../agenda/store/agenda.hooks';
import FeedbackModal from './FeedbackModal';
import { matchDefinedValues } from '../../../utils';

function useWorkshopFeedaback(agenda: any, field: string) {
  return useMemo(() => {
    // Find active feedback ?
    const workshopWithFeedback = agenda.find((ws: any) => (ws[field]?.[0] || {})?.uri);
    if (!workshopWithFeedback) return {};
    const feedbackFormUri = (workshopWithFeedback[field]?.[0] || {})?.uri;
    return { workshop: workshopWithFeedback, feedbackFormUri };
  }, [agenda, field]);
}

type WorkshopFeedbackPopupBlockProps = BlockProps & {
  agendaFilter?: Record<string, any>;
  field?: string;
} & typeof WorkshopFeedbackPopupBlockDefaultProps;

const WorkshopFeedbackPopupBlockDefaultProps = {
  agendaFilter: undefined,
  field: 'feedbacks',
};

const WorkshopFeedbackPopupBlock = ({
  agendaFilter,
  field,
}: WorkshopFeedbackPopupBlockProps): JSX.Element | null => {
  const [state, setState] = useSetState({
    showFeedbackForm: null,
    lastShownId: null,
    feedbackUrl: null,
  });

  const { showFeedbackForm, lastShownId, feedbackUrl } = state;
  const agenda = useFreshAgendaWorkshops();
  const filteredAgenda = agendaFilter ? agenda.filter(matchDefinedValues(agendaFilter)) : agenda;
  const { workshop, feedbackFormUri } = useWorkshopFeedaback(filteredAgenda, field);

  useEffect(() => {
    if (feedbackFormUri !== lastShownId) {
      setState({ showFeedbackForm: feedbackFormUri, lastShownId: feedbackFormUri });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedbackFormUri]);

  useEffect(() => {
    async function checkFeedback() {
      if (feedbackFormUri && !showFeedbackForm) {
        const api = `${feedbackFormUri.replace('/html', '/api')}/workshops/${workshop._id}/users/${
          store.userId
        }`;
        const res = await platformService.secureGet(api);
        if (res) {
          const { status, token } = res;
          if (status === 'display') {
            // Generate workshop-specific uri
            setState({
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              feedbackUrl: `${feedbackFormUri}/workshops/${workshop._id}?token=${token}`,
            });
          } else if (status === 'done') {
            // Already good
            setState({ feedbackUrl: null, showFeedbackForm: null });
          }
        }
      }
    }
    checkFeedback();
  }, [workshop?._id, showFeedbackForm, feedbackFormUri]);

  if (!feedbackUrl || !feedbackFormUri) {
    return null;
  }
  return (
    <FeedbackModal
      item={workshop}
      onClose={() => setState({ feedbackUrl: null })}
      feedbackUrl={feedbackUrl}
    />
  );
};

WorkshopFeedbackPopupBlock.defaultProps = WorkshopFeedbackPopupBlockDefaultProps;

export default WorkshopFeedbackPopupBlock;
