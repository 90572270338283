import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button, Icon } from 'semantic-ui-react';
import { useComponentOptions } from '../../config/design.context';
import { bem } from '../../core/design/bem';
import './BackButton.scss';

const css = bem('BackButton');

type BackButtonOptions = {
  enabled?: boolean;
  className?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  forceBackUrl?: string;
  iconProps?: any;
  inverted?: boolean;
  label?: string;
  variant?: 'button' | 'link' | 'square-icon';
};

type BackButtonProps = {
  className?: string;
};

const BackButton = (props: BackButtonProps): JSX.Element | null => {
  const { className, ...otherProps } = props;
  const history = useHistory();
  const {
    enabled,
    label,
    variant = 'button',
    inverted,
    iconProps,
    forceBackUrl,
    ...buttonProps
  } = useComponentOptions('BackButton') as BackButtonOptions;
  const { t } = useTranslation();

  if (!enabled) return null;

  if (variant === 'square-icon' || variant === 'link') {
    return (
      <a
        href={undefined}
        {...buttonProps}
        {...otherProps}
        title="back"
        className={css({ variant, inverted }).mix(className, buttonProps.className).toString()}
        onClick={(e) => {
          e.preventDefault();
          if (forceBackUrl) {
            history.push(forceBackUrl);
          } else history.goBack();
        }}
      >
        <Icon name="angle left" bordered={variant === 'square-icon'} {...iconProps} />{' '}
        {label || t('btn.back')}
      </a>
    );
  }

  return (
    <Button
      {...buttonProps}
      {...otherProps}
      className={css({ variant, inverted }).mix(className, buttonProps.className).toString()}
      onClick={() => {
        if (forceBackUrl) {
          history.push(forceBackUrl);
        } else history.goBack();
      }}
    >
      <Icon name="angle left" {...iconProps} /> {label || t('btn.back')}
    </Button>
  );
};

BackButton.defaultProps = {
  className: undefined,
};

export default BackButton;
