/* eslint-disable react/no-danger */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Dimmer, Grid, Header, Loader } from 'semantic-ui-react';

import { steps } from './Appointments';
import { useCurrentAppointment, useDaysWithSlots, useFreeTimeSlots } from './appointments.hooks';
import { bookAppointment } from './utils';

import { useTracking } from '../Context';

import { CmsBlocks } from '../cms/CmsScreen';

import BlockContainer from '../components/BlockContainer';

import { bem } from '../core/design/bem';

import { cancelAppointment } from '../sponsors/blocks/BookAppointmentBlock/utils';

import { StepContext } from './steps/StepContext';

const css = bem('appointment');

function AppointmentsBlock(props) {
  const {
    header,
    footer,
    container,
    main,
    days,
    appointmentUserRef,
    extraBookingData,
    slotFilters,
  } = props;
  const { _id: groupId, collection } = appointmentUserRef;
  const { header: mainHeader, details } = main;
  const [step, setStep] = useState('select-slot');
  const [confirmData, setConfirmData] = useState({});
  const [selectedSlot, setSelectedSlot] = useState(null);
  const { trackEvent } = useTracking();

  const currentAppointment = useCurrentAppointment(collection, groupId);
  // userId remplacé par groupId car platfom front s'attend a un groupID
  const timeSlots = useFreeTimeSlots(collection, groupId, slotFilters);
  const { freeTimeSlots, loading } = timeSlots;
  const daysWithSlots = useDaysWithSlots(days, freeTimeSlots);

  useEffect(() => {
    setStep(currentAppointment ? 'prepare' : 'select-slot');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAppointment?._id]);

  function handleStep1Select(slot) {
    setSelectedSlot(slot);
    setStep('confirm');
  }

  async function handleConfirm(data) {
    const { startDate, endDate } = selectedSlot;
    const appointmentToBook = {
      startDate,
      endDate,
      groupId,
      message: data.message,
      ...extraBookingData,
    };

    await bookAppointment(appointmentUserRef, appointmentToBook, {
      setStep,
      previousAppointment: currentAppointment,
    });

    setConfirmData(data);
  }

  async function handleCancel() {
    if (!currentAppointment) return;
    cancelAppointment(currentAppointment, appointmentUserRef, { trackEvent });
  }

  function handleUpdate() {
    setStep('select-slot');
  }

  const { Sidebar, Content } = steps[step] || {};

  return (
    <div className="appointments-block">
      {header && <CmsBlocks blocks={header.blocks} />}
      <BlockContainer {...container}>
        {mainHeader && <Header as="h1">{mainHeader}</Header>}
        {details && <div dangerouslySetInnerHTML={{ __html: details }} />}
        <StepContext.Provider
          value={{
            days: daysWithSlots,
            onSelect: handleStep1Select,
            onConfirm: handleConfirm,
            onCancel: handleCancel,
            onUpdate: handleUpdate,
            appointmentUserRef,
            setStep,
            freeTimeSlots,
            selectedSlot,
            confirmData,
            currentAppointment,
          }}
        >
          {loading ? (
            <Dimmer active inverted>
              <Loader inverted content="Loading" />
            </Dimmer>
          ) : (
            <Grid
              stackable
              style={{ marginTop: '1em', marginBottom: '1em' }}
              className={css({ hasAppointment: !!currentAppointment, step }).toString()}
            >
              <Grid.Column width={4}>{Sidebar && <Sidebar />}</Grid.Column>
              <Grid.Column width={12}>{Content && <Content />}</Grid.Column>
            </Grid>
          )}
        </StepContext.Provider>
      </BlockContainer>
      {footer && <CmsBlocks blocks={footer.blocks} />}
    </div>
  );
}

AppointmentsBlock.defaultProps = {
  appointmentUserRef: {},
  slotFilters: undefined,
  container: { type: 'segment' },
  days: [],
  extraBookingData: {},
  footer: undefined,
  header: undefined,
  main: {},
};

AppointmentsBlock.propTypes = {
  appointmentUserRef: PropTypes.shape({
    collection: PropTypes.string.isRequired,
    _id: PropTypes.string,
  }),
  slotFilters: PropTypes.object,
  container: PropTypes.object,
  days: PropTypes.arrayOf(PropTypes.object),
  extraBookingData: PropTypes.object,
  footer: PropTypes.object,
  header: PropTypes.object,
  main: PropTypes.object,
};

export default AppointmentsBlock;
