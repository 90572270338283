import cx from 'classnames';
import partition from 'lodash/partition';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Card, Label } from 'semantic-ui-react';
import { userAgenda } from '../../agenda/store/agenda.selectors';
import { checkUserAvailability } from '../../utils/agendaUtils';
import Images from '../../utils/Images';
import Tag from '../../workshops/components/Tag';
import { getClassName, getWorkshopString } from '../../workshops/utils';
import EntityLink from '../EntityLink';
import SpeakerAvatars from '../speakers/SpeakerAvatars';
import QuotaField from './QuotaField';
import './WorkshopCard3.scss';
import WorkshopDate from './WorkshopDate';

const translationPrefix = 'workshops.card';

const WorkshopCard3 = ({
  workshop,
  hasSessions,
  showSpeakers,
  showDuration,
  showEndTime,
  speakersConfig,
}) => {
  const {
    _id,
    endDate,
    image,
    productCategories,
    quota,
    shortDescription,
    speakers,
    startDate,
    technicityLevel,
    title,
    usersCount,
    workshopStatus,
  } = workshop;
  const { t } = useTranslation();

  const hasImage = image && image.uri;
  const agenda = useSelector(userAgenda);
  const { hasOverlappingEvent, isRegistered } = useMemo(() => {
    const [registeredEvent, otherEvents] = partition(agenda, (evt) => evt._id === _id);
    return {
      hasOverlappingEvent: !checkUserAvailability(otherEvents, workshop),
      isRegistered: registeredEvent.length > 0,
    };
  }, [agenda, workshop, _id]);

  return (
    <div
      className={cx(
        'workshop--card3',
        getClassName(workshop),
        `session--${workshopStatus}`,
        hasOverlappingEvent && `session--has-overlapping-event`,
        isRegistered && `session--is-registered`,
      )}
    >
      <Card link as={EntityLink} entity={workshop}>
        {hasImage && (
          <div className="card__image">
            <div
              className="image-with-shadow"
              style={{
                backgroundImage: `url(${Images.maxWidth(image, 800)})`,
              }}
            />
          </div>
        )}
        {hasImage && <Card.Content style={{ marginTop: 'calc(56.25% - 70px)', borderTop: 0 }} />}
        <Card.Content className="borderless">
          <p className="workshop--category">{getWorkshopString(workshop, 'category')}</p>
          <Card.Description style={{ marginTop: 8, marginBottom: 8 }}>
            <WorkshopDate
              startDate={startDate}
              endDate={endDate}
              showDuration={showDuration}
              showEndTime={showEndTime}
            />
          </Card.Description>
          <QuotaField quota={quota} usersCount={usersCount} />
          <Card.Header style={{ fontSize: '1.2em', fontWeight: 'normal' }}>{title}</Card.Header>
          {showSpeakers && <SpeakerAvatars speakerIds={speakers} {...speakersConfig} />}
          <div className="tags">
            {technicityLevel && <Label size="small">{technicityLevel}</Label>}
            {productCategories &&
              productCategories.length > 0 &&
              productCategories.map((pc) => (
                <Tag
                  key={pc}
                  size="small"
                  value={pc}
                  translationPrefix="workshop.tag.productCategories"
                />
              ))}
          </div>
          {/* <p style={{ lineHeight: '1.25', color: 'rgba(0, 0, 0, 0.5)', marginTop: 8 }}>
              Technicity Level : {technicityLevel}
            </p> */}
          <Card.Description>{shortDescription}</Card.Description>
          {!hasSessions && (
            <div className="card__read-more">
              {t(`${translationPrefix}.read-more`, {
                context: isRegistered ? 'registered' : undefined,
              })}
            </div>
          )}
        </Card.Content>
      </Card>
    </div>
  );
};

WorkshopCard3.defaultProps = {
  hasSessions: false,
  showSpeakers: false,
  showDuration: false,
  showEndTime: false,
  speakersConfig: {},
};

WorkshopCard3.propTypes = {
  hasSessions: PropTypes.bool,
  showSpeakers: PropTypes.bool,
  showDuration: PropTypes.bool,
  showEndTime: PropTypes.bool,
  speakersConfig: PropTypes.object,
  workshop: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    category: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.string.isRequired,
    ]),
    collection: PropTypes.string.isRequired,
    endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    image: PropTypes.object,
    productCategories: PropTypes.array,
    quota: PropTypes.number,
    technicityLevel: PropTypes.string,
    title: PropTypes.string.isRequired,
    startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    shortDescription: PropTypes.string,
    slug: PropTypes.string,
    speakers: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])),
    usersCount: PropTypes.number,
    workshopStatus: PropTypes.string,
  }).isRequired,
};

export default WorkshopCard3;
