/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import PropTypes from "prop-types";
import { userArrayProptypes } from '../../../propTypes';
import { avatarWithFallbackWhenMissing } from '../../../utils/avatarUtils';
import ItemGridBlock from '../../cms/ItemGridBlock';
import EntityLink from '../../EntityLink';

const DetailsVariant = (props) => {
  const { users, gridProps, ...rest } = props;

  const { imageKey, imageSize, showModal } = rest;
  const items = users.map((user) => ({
    ...user,
    title: `${user.firstName || ''} ${user.lastName || ''}`,
    fullDescription: user.description,
    description: user.role,
    image: avatarWithFallbackWhenMissing(user[imageKey]),
  }));

  return (
    <div>
      <ItemGridBlock
        item={{ imageSize }}
        container={{ type: 'headerless' }}
        grid={{ doubling: true, ...gridProps }}
        items={items}
        as={showModal ? EntityLink : undefined}
      />
    </div>
  );
};

DetailsVariant.propTypes = {
  gridProps: PropTypes.object,
  users: userArrayProptypes,
};
DetailsVariant.defaultProps = {
  gridProps: undefined,
  users: [],
};

export default DetailsVariant;
