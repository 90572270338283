import { isAfter, parseISO, startOfDay } from 'date-fns';
import { orderBy } from 'lodash';

export function hasActiveLive(session) {
  if (!session) return false;

  const { liveStreams } = session;
  return liveStreams?.length > 0 && liveStreams[0].visible;
}

export function computeMidTime({ startDate, endDate }) {
  if (!startDate && !endDate) return null;
  const startAt = parseISO(startDate);
  const endAt = parseISO(endDate);
  return new Date((startAt.getTime() + endAt.getTime()) / 2);
}

export function findNextSession(sessions, now) {
  const nowDate = new Date(now);
  if (sessions?.length === 0) return null;
  const candidateSessions = sessions.filter(({ startDate, endDate }) => startDate && endDate);

  // Order sessions
  const sortedSessions = orderBy(candidateSessions, 'startDate');
  return sortedSessions.find((s) => isAfter(computeMidTime(s), nowDate));
}

// 03/01 23H30 - 04/01 00h30 UTC

// 03/01 23H30 - 04/01 23h59 UTC
// 00h00 UTC => 04/01

function startOfDayOr6hoursAgo(date) {
  const start = startOfDay(date).getTime();
  const hoursAgo = new Date(date.getTime() - 6 * 3600 * 1000);
  return new Date(Math.min(hoursAgo, start));
}

// TODO: almost identical to currentLiveSession...
export function currentLiveSession(sessions, now) {
  if (sessions?.length === 0) return null;

  const nowDate = new Date(now);
  const today = startOfDayOr6hoursAgo(nowDate);

  // Today or later sessions
  const todayOrLaterSessions = sessions.filter(({ startDate }) =>
    isAfter(parseISO(startDate), today),
  );
  const candidateSessions = todayOrLaterSessions.filter(({ noLive }) => !noLive);
  // Find first session that has an active live
  const activeLive = candidateSessions.find(hasActiveLive);
  if (activeLive) return activeLive;

  // Didn't find any live. Find next "upcoming" session
  return candidateSessions.find((s) => isAfter(parseISO(s.endDate), nowDate));
}

export function upcomingLiveSession(sessions, now) {
  if (sessions?.length === 0) return null;

  const nowDate = new Date(now);
  const today = startOfDayOr6hoursAgo(nowDate); // TODO min avec now-6h
  // Today or later sessions
  const todayOrLaterSessions = sessions.filter(({ startDate }) =>
    isAfter(parseISO(startDate), today),
  );
  const candidateSessions = todayOrLaterSessions.filter(({ noLive }) => !noLive);
  // Find first session that has an active live
  const activeLive = candidateSessions.find(hasActiveLive);
  if (activeLive) return activeLive;

  // Didn't find any live. Find next "upcoming" session
  return candidateSessions.find((s) => isAfter(computeMidTime(s), nowDate));
}
