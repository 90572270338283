import get from 'lodash/get';
import keys from 'lodash/keys';
import values from 'lodash/values';
import groupBy from 'lodash/groupBy';
import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import pick from 'lodash/pick';
import { useSelector } from 'react-redux';
import { generateWorkshopStatus } from '../../../utils/groupUtils';
import {
  formatWorkshops,
  useRequiredTimeSlots,
} from '../../../workshops/components/WorkshopTable/WorkshopTable';
import { useConfig } from '../../../config/config.context';
import { useMe } from '../../../profile/hooks';
import { useSyncedCollectionWorkshopSessions } from '../../../workshop-session/store/workshopSessions.hooks';

const registrationFields = ['title', 'collection', 'startDate', 'endDate'];

export function useUserHasRequireAgendaEntries(collection) {
  const user = useMe();
  const { timezones, defaultTimezone } = useConfig();
  const workshopList = useSyncedCollectionWorkshopSessions(collection);
  const registrations = useSelector((state) => state.registrations.registrations);
  const workshops = generateWorkshopStatus(workshopList);
  const userTimezone = get(user, 'timezone', defaultTimezone);
  const timezone = userTimezone || timezones?.[0]?.key || 'Europe/Paris';

  const { days, formattedWorkshops } = formatWorkshops(workshops, timezone);

  const workshopsByDay = groupBy(formattedWorkshops, 'day');
  const requiredChoiceTimeSlots = useRequiredTimeSlots(days, workshopsByDay);

  if (keys(requiredChoiceTimeSlots).length > 0) {
    const registrationsByDate = mapValues(keyBy(registrations, 'startDate'), (r) => {
      return { ...pick(r, registrationFields), workshopId: r.workshopId, sessionId: r._id };
    });
    const requiredTimeSlots = keys(requiredChoiceTimeSlots);
    const requiredSelectedCount = values(pick(registrationsByDate, requiredTimeSlots)).length;
    const hasRequiredSlots = requiredSelectedCount >= requiredTimeSlots.length;
    return { hasRequiredSlots };
  }
  return { hasRequiredSlots: true };
}
