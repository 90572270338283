import React from 'react';
import cx from 'classnames';
import './Step.scss';

// eslint-disable-next-line react/prop-types
const Step = ({ label, top, color, active, success, style }) => {
  return (
    <div className="Step vertical" style={style}>
      <div className={cx('Step__line', top && 'show')} />
      <div
        className={cx('Step__step', { active, success })}
        style={{ backgroundColor: active && color }}
      >
        {(active || success) && label}
      </div>
      <div className={cx('Step__line', 'show')} />
    </div>
  );
};

// eslint-disable-next-line react/prop-types
export const StepContainer = ({ children, active, success, ...props }) => (
  <div className="Step__container">
    <Step active={active} success={success} {...props} />
    <div className={cx('Step__child', !active && !success && 'disabled')}>{children}</div>
  </div>
);

export default Step;
