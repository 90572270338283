import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { Container } from 'semantic-ui-react';

import Styles from '../Styles';

import { routeComponents } from '../Layouts/Default/DefaultRoutes';

import CmsPage from '../cms/CmsPage';

import PageBackground from '../components/PageBackground';

import { useConfig, useUpdateConfig } from '../config/config.context';
import { useDesignConfig } from '../config/design.context';
import { PreviewMode } from '../config/preview.context';
import { useScreenConfig } from '../config/screens.context';

import { getCurrentPage } from '../core/trackers/utils';
import { setUsers } from '../core/users/users.actions';

import { setPrograms } from '../programs/store/programs.actions';

import store from '../shared/Store';

import { setSponsors } from '../sponsors/store/sponsors.actions';

import { setWorkshops } from '../workshops/store/workshops.actions';

const PagePreview = ({ match }) => {
  const { name } = match.params;
  const { homeBackground } = useDesignConfig();
  const { screens } = useConfig();
  const updateConfig = useUpdateConfig();
  const screenConfig = useScreenConfig(name);
  store.isPreview = true;

  useEffect(() => {
    const listener = (event) => {
      const { type, payload } = event.data;
      if (type === 'platform' && payload) {
        const { config, workshops, sponsors, programs, speakers } = payload;
        updateConfig(config);
        if (workshops) store.reduxStore.dispatch(setWorkshops(workshops));
        if (sponsors) store.reduxStore.dispatch(setSponsors(sponsors));
        if (programs) store.reduxStore.dispatch(setPrograms(programs));
        if (speakers) store.reduxStore.dispatch(setUsers('speakers', speakers));
      }
    };
    window.addEventListener('message', listener, false);
    return () => {
      window.removeEventListener('message', listener, false);
    };
  }, [updateConfig]);

  const screen = getCurrentPage(screens, `/${name}`) || screenConfig;

  const Component = get(routeComponents, screen?.type, CmsPage);

  return (
    <PreviewMode>
      <Provider store={store.reduxStore}>
        <Styles />
        <Container style={{ marginTop: 16 }}>
          <PageBackground image={homeBackground} {...screen?.design} />
          <Component name={name} {...screen} />
        </Container>
      </Provider>
    </PreviewMode>
  );
};

PagePreview.propTypes = {
  match: PropTypes.object.isRequired,
};

export default PagePreview;
