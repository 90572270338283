/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Button, Modal } from 'semantic-ui-react';
import { userAgenda } from '../../../agenda/store/agenda.selectors';
import { CmsBlocks } from '../../../cms/CmsScreen';
import { bem } from '../../../core/design/bem';
import { useMe } from '../../../profile/hooks';
import { evalValue } from '../../../utils/constraints';
import { useUserHasRequireAgendaEntries } from './utils';

const css = bem('CmsModalBlock');

type Block = {
  id: string;
  type: string;
};

type CmsModalBlockProps = {
  blocks?: Block[];
  condition?: Record<string, any>;
  config?: Record<string, any>;
  header?: string;
};

const CmsModalBlock = ({
  blocks,
  config = {},
  condition,
  header,
}: CmsModalBlockProps): React.ReactElement | null => {
  const { t } = useTranslation();
  const location = useLocation();
  const [baseLocation] = useState(location.pathname);
  const [isOpen, setIsOpen] = useState(true);
  const user = useMe();
  const agenda = useSelector(userAgenda);
  const { size, showActions, agendaConfig } = config;
  const { collection } = agendaConfig || {};
  const { hasRequiredSlots } = useUserHasRequireAgendaEntries(collection);

  useEffect(() => {
    if (baseLocation !== location.pathname) {
      setIsOpen(false); // Auto-close
    }
  }, [baseLocation, location.pathname]);

  const isVisible = condition ? evalValue(condition, { user, agenda, hasRequiredSlots }) : false;

  if (!isVisible || !isOpen) return null;
  return (
    <Modal
      className={css().toString()}
      open={isOpen}
      size={size}
      closeIcon
      dimmer={{ className: css('Dimmer').toString() }}
      onClose={() => setIsOpen(false)}
    >
      {header && <Modal.Header className={css('header').toString()}>{header}</Modal.Header>}
      <Modal.Content className={css('content').toString()}>
        <CmsBlocks blocks={blocks} />
      </Modal.Content>
      {showActions && (
        <Modal.Actions>
          <Button>{t('btn.cancel')}</Button>
        </Modal.Actions>
      )}
    </Modal>
  );
};

CmsModalBlock.defaultProps = {
  blocks: [],
  condition: undefined,
  config: {},
  header: undefined,
};

export default CmsModalBlock;
