import PropTypes from 'prop-types';
import React from 'react';
import { getString } from '../../utils';
import WorkshopsBlock from '../components/WorkshopsBlock';
import { useWorkshopQuery } from '../store/workshops.hooks';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const QueryWorkshops = ({ from, orderBy, children: childFunction }) => {
  const items = useWorkshopQuery({ from, orderBy });
  return childFunction(items);
};

function expandQueryForItem(query, item) {
  if (!query) return query;
  return {
    ...query,
    from: {
      ...query.from,
      collection: query.from?.collection === true ? item.collection : query.from?.collection,
      category: query.from?.category === true ? item.category : query.from?.category,
    },
  };
}

const WorkshopSeeAlsoBlock = ({ item, field, header, query, ...props }) => {
  if (query) {
    return (
      <QueryWorkshops {...expandQueryForItem(query, item)}>
        {(items) => {
          const others = (items || []).filter((i) => i._id !== item._id);
          if (others.length === 0) return null;
          return (
            <WorkshopsBlock
              {...props}
              className="workshop--seeAlsoBlock"
              header={header || getString(`workshops.workshop.see-also`)}
              ids={others.map((i) => i._id)}
            />
          );
        }}
      </QueryWorkshops>
    );
  }

  const entries = item[field];
  if (!entries || entries.length === 0) return null;

  return (
    <WorkshopsBlock
      {...props}
      className="workshop--seeAlsoBlock"
      header={header || getString(`workshops.workshop.see-also`)}
      ids={entries}
    />
  );
};

WorkshopSeeAlsoBlock.defaultProps = {
  header: undefined,
  field: 'seeAlso',
};

WorkshopSeeAlsoBlock.propTypes = {
  item: PropTypes.object.isRequired,
  field: PropTypes.string,
  header: PropTypes.string,
};

export default WorkshopSeeAlsoBlock;
