import React, { useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';
import ImageIcon from '../../../../components/ImageIcon';
import {
  EntityShareButton,
  getActionOptions,
  useActionRegistration,
} from '../../../../workshops/components/WorkshopActions';
// eslint-disable-next-line import/no-cycle
import UserRegistrationModal from '../../../../authentication/components/UserRegistrationModal/UserRegistrationModal';

const ActionButton = ({ action, onClick }) => {
  const { key, icon, style, size, className } = action;
  return (
    <Button
      size={size || 'small'}
      className={cx('action', key, className)}
      onClick={() => onClick(action)}
      style={style}
      icon
    >
      <ImageIcon icon={icon} style={{ backgroundColor: 'transparent' }} />
    </Button>
  );
};

ActionButton.defaultProps = {
  className: '',
};

ActionButton.propTypes = {
  className: PropTypes.string,
  action: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

function CloudTvActions({ actions, onClick, session }) {
  const { t } = useTranslation();
  const {
    isAnonymousUser,
    registerWorkshop,
    unregisterWorkshop,
    registerWorkshopSession,
    unregisterWorkshopSession,
    registration,
  } = useActionRegistration(session);
  const [isOpen, setIsOpen] = useState(false);
  if (!session || !actions || actions.length === 0) return null;

  const handleAction = (action) => {
    const formattedAction = { ...action, ...getActionOptions(action, registration, t) };
    switch (formattedAction.type) {
      case 'save': {
        if (isAnonymousUser) {
          setIsOpen(true);
        } else if (!registration) {
          if (session.workshopId) registerWorkshopSession();
          else registerWorkshop();
        }
        return formattedAction.type;
      }
      case 'unsave': {
        if (isAnonymousUser) {
          setIsOpen(true);
        } else if (session.workshopId) unregisterWorkshopSession();
        else unregisterWorkshop();
        return formattedAction.type;
      }
      default:
        return onClick(formattedAction);
    }
  };
  return (
    <>
      <div className="actions">
        {actions.map((action) => {
          const { key } = action;
          if (key === 'share') {
            return (
              <EntityShareButton
                key={key}
                action={{ ...action, label: '' }}
                onClick={handleAction}
                entity={session}
              />
            );
          }
          return (
            <ActionButton
              key={key}
              action={{ ...action, ...getActionOptions(action, registration, t) }}
              onClick={handleAction}
            />
          );
        })}
      </div>
      {isOpen && (
        <UserRegistrationModal
          onClose={() => {
            setIsOpen(false);
          }}
        />
      )}
    </>
  );
}

CloudTvActions.defaultProps = {
  actions: undefined,
};

CloudTvActions.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.object),
  onClick: PropTypes.func.isRequired,
  session: PropTypes.object.isRequired,
};

export default CloudTvActions;
