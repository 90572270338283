/* eslint-disable @typescript-eslint/no-unused-vars */
import PropTypes from 'prop-types';
import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from 'semantic-ui-react';

import { workshopArrayProptypes } from '../../../../propTypes';
import {
  durationInMinutes,
  formatDateRangeDuration,
  formatTimezoneDate,
} from '../../../../utils/dateUtils';

const translationPrefix = 'workshops.workshop.modal';

const SessionDates = ({ registration, workshopSessions, showDuration, showLocation }) => {
  const { t } = useTranslation();

  if (!workshopSessions || workshopSessions.length === 0) return null;
  const hasDuration =
    showDuration && workshopSessions[0]?.startDate && workshopSessions[0]?.endDate;
  const hasLocation = showLocation && workshopSessions[0]?.location;
  return (
    <div className="session-dates">
      <Icon name="calendar outline" style={{ marginRight: 8 }} />

      {workshopSessions.map((workshopSession, idx) => {
        const {
          _id: sessionId,
          startDate: sessionStartDate,
          endDate: sessionEndDate,
          timezone,
        } = workshopSession;
        const formattedStartDate = formatTimezoneDate(sessionStartDate, timezone);
        const formattedEndDate = formatTimezoneDate(sessionEndDate, timezone);
        const isRegistered = registration?._id === sessionId;
        return (
          <span style={{ marginRight: 4 }} className={cx('date', { active: isRegistered })}>
            {t(`${translationPrefix}.session-date`, {
              startDate: formattedStartDate,
              endDate: formattedEndDate,
              timezone,
            })}
            {idx !== workshopSessions.length - 1 && '  /  '}
          </span>
        );
      })}
      {!!hasDuration && (
        <div className="sesion-duration">
          <Icon name="hourglass start" style={{ marginRight: 8 }} />
          {t(`${translationPrefix}.session-duration`, {
            duration: formatDateRangeDuration(
              workshopSessions[0]?.startDate,
              workshopSessions[0]?.endDate,
              t,
            ),
          })}
        </div>
      )}
      {!!hasLocation && (
        <div className="session-location">
          <Icon name="map marker alternate" style={{ marginRight: 8 }} />
          {workshopSessions[0].location}
        </div>
      )}
    </div>
  );
};

SessionDates.defaultProps = {
  registration: undefined,
  showDuration: false,
  showLocation: false,
  workshopSessions: [],
};

SessionDates.propTypes = {
  registration: PropTypes.object,
  showDuration: PropTypes.bool,
  showLocation: PropTypes.bool,
  workshopSessions: workshopArrayProptypes,
};

export default SessionDates;
