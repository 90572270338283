import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Button, Card, Header } from 'semantic-ui-react';
import EntityLink from '../../../components/EntityLink';
import SpeakerAvatars from '../../../components/speakers/SpeakerAvatars';
import ReplayModal from '../../../components/video/ReplayModal';
import WorkshopDate from '../../../components/workshops/WorkshopDate';
import { workshopProptypes } from '../../../propTypes';
import { getString } from '../../../utils';
import Images from '../../../utils/Images';
import { bem } from '../../../core/design/bem';
import WorkshopRegistrationButton from '../../../workshops/components/WorkshopRegistrationButton';
import { getClassName, getWorkshopString } from '../../../workshops/utils';
import { useWorkshopRelatedEvent } from '../../../workshops/utils/workshopRegistrationUtils';
import './WorkshopImageItem.scss';
import './WorkshopLightItem.scss';

const translationPrefix = 'workshops.workshop';

const classes = {
  seeMoreButton: bem('see-more-button'),
  eventStatusButton: bem('event-status-button'),
};

const SeeMoreButton = ({ workshop }) => {
  const hasLive =
    workshop.liveStreams && workshop.liveStreams.length > 0 && !!workshop.liveStreams[0].uri;

  if (hasLive) {
    return (
      <Button
        className="live-button"
        as={EntityLink}
        entity={workshop}
        primary
        icon="play"
        content={getString(`${translationPrefix}.btn.live`)}
      />
    );
  }

  return (
    <Button
      className={classes.seeMoreButton.toString()}
      as={EntityLink}
      entity={workshop}
      content={getString(`${translationPrefix}.see-more`)}
    />
  );
};

const EventStatusButton = ({ workshop, status }) => {
  const label = getString(`${translationPrefix}.event-status.${status}`);
  const details =
    status === 'accept' || status === 'refuse' ? (
      <span className={classes.eventStatusButton({ status }, 'details').toString()}>
        {getString(`${translationPrefix}.event-status.${status}-details`)}
      </span>
    ) : null;

  return (
    <Button
      className={classes.eventStatusButton({ status }).toString()}
      as={EntityLink}
      entity={workshop}
      content={
        <>
          {label}
          {details}
        </>
      }
    />
  );
};

EventStatusButton.propTypes = {
  workshop: PropTypes.object.isRequired,
  status: PropTypes.string.isRequired,
};

export const WorkshopActions = ({
  workshop,
  showActions,
  showDetails,
  showReplay,
  showEventStatus,
}) => {
  const { _id: workshopId, pageHidden, pageLiveOnlyForRegistered } = workshop;

  const isUserRegistered = useSelector(
    (state) => state.registrations.registrationsById[workshopId],
  );

  const { eventId: relatedEventId, status: eventStatus } = useWorkshopRelatedEvent(workshop);
  const isEventStatusDisplayed = showEventStatus && !!relatedEventId;

  const isButtonDisplayed =
    showDetails && !pageHidden && (isUserRegistered || !pageLiveOnlyForRegistered);

  const hasReplay =
    workshop.replays && workshop.replays.length > 0 && workshop.replays[0].value?.uri;

  return (
    <div className="actions">
      {showActions && <WorkshopRegistrationButton workshop={workshop} />}
      {showReplay && hasReplay && (
        <ReplayModal
          item={workshop}
          replay={workshop.replays[0]}
          trigger={
            <Button className="replay-button">{getString(`${translationPrefix}.replay`)}</Button>
          }
        />
      )}
      {isEventStatusDisplayed && <EventStatusButton workshop={workshop} status={eventStatus} />}
      {isButtonDisplayed && <SeeMoreButton workshop={workshop} />}
    </div>
  );
};

SeeMoreButton.propTypes = {
  workshop: PropTypes.shape(workshopProptypes).isRequired,
};

WorkshopActions.defaultProps = {
  showActions: true,
  showDetails: true,
  showReplay: true,
  showEventStatus: true,
};

WorkshopActions.propTypes = {
  workshop: PropTypes.shape(workshopProptypes).isRequired,
  showActions: PropTypes.bool,
  showDetails: PropTypes.bool,
  showReplay: PropTypes.bool,
  showEventStatus: PropTypes.bool,
};

const WorkshopImageItem = ({ workshop, config }) => {
  const {
    showActions = true,
    showSpeakers = true,
    showDate = true,
    showEndTime = false,
    showDetails = true,
    showReplay = true,
    showEventStatus = true,
    speakerConfig = {},
  } = config;
  const { shortDescription, image, speakers, title, startDate, endDate, category } = workshop;
  return (
    <Card className={cx('Workshop', getClassName(workshop))}>
      <Card.Content className="Workshop__Container">
        {image && image.uri && (
          <div
            className="Workshop__Container--Image"
            style={{
              backgroundImage: `url(${Images.cdn(image)})`,
            }}
          />
        )}
        <div className="Workshop__Container--Content">
          <div style={{ flex: 1 }}>
            {title && (
              <Header className="title" as="h3">
                {title}
              </Header>
            )}
            {category && <p className="category">{getWorkshopString(workshop, 'category')}</p>}
            {showDate && (
              <Card.Description className="date">
                <WorkshopDate startDate={startDate} endDate={endDate} showEndTime={showEndTime} />
              </Card.Description>
            )}
            {shortDescription && (
              <Card.Description className="description">{shortDescription}</Card.Description>
            )}
            {showSpeakers && (
              <div className="speakers">
                <SpeakerAvatars size="mini" speakerIds={speakers} {...speakerConfig} />
              </div>
            )}
          </div>
          {(showActions || showDetails) && (
            <WorkshopActions
              workshop={workshop}
              showActions={showActions}
              showDetails={showDetails}
              showReplay={showReplay}
              showEventStatus={showEventStatus}
            />
          )}
        </div>
      </Card.Content>
    </Card>
  );
};

WorkshopImageItem.defaultProps = {
  config: {},
};

WorkshopImageItem.propTypes = {
  config: PropTypes.object,
  workshop: PropTypes.shape(workshopProptypes).isRequired,
};

export default WorkshopImageItem;
