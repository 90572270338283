import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Button, Checkbox, Container, Header, Icon, Segment, Tab } from 'semantic-ui-react';

import './NetworkingScreen.scss';

import { useTracking } from '../Context';
import { getString } from '../utils';

import { useScreenConfig } from '../config/screens.context';

import { eventTags } from '../core/trackers/events';

import store from '../shared/Store';

import useNetworkingTabs from './hooks/useNetworkingTabs';
import { CmsPageLayout } from '../cms/CmsPage';
import { CmsBlocks } from '../cms/CmsScreen';

const translationRoot = 'networking';
const tabMenuConfig = { style: { overflowX: 'auto', overflowY: 'hidden' } };
const NetworkingScreen = () => {
  const { tabs, activePane } = useNetworkingTabs();

  return (
    <Tab
      panes={tabs}
      menu={tabMenuConfig}
      renderActiveOnly
      activeIndex={activePane}
      className="NetworkingScreen"
    />
  );
};

const ChatShield = ({ pageHeader, headerBlocks }) => {
  const { trackEvent } = useTracking();

  const accepted = useSelector((state) => state.user.user?.networking);
  const { enableNetworkingFromScreen = false } = useScreenConfig('networking');

  function trackNetworkingStatus(status) {
    const tag = status ? eventTags.NETWORKING_ENABLE : eventTags.NETWORKING_DISABLE;
    trackEvent(tag, {
      userId: store.userId,
    });
  }

  if (!accepted) {
    return (
      <CmsPageLayout className={cx('page', 'page--cms', 'networking')} pageHeader={pageHeader}>
        <Container>
          {headerBlocks && <CmsBlocks blocks={headerBlocks.blocks || []} />}
          <Segment placeholder style={{ textAlign: 'center' }}>
            <Header icon>
              <Icon name="share alternate" />
              {getString(`${translationRoot}.not-accepted`)}
            </Header>
            <p>{getString(`${translationRoot}.not-accepted-message`)}</p>
            <Segment.Inline>
              {enableNetworkingFromScreen ? (
                <Checkbox
                  toggle
                  label={
                    // eslint-disable-next-line jsx-a11y/label-has-associated-control
                    <label>{getString(`profile.enableNetworking`)}</label>
                  }
                  onClick={(e, { checked }) => {
                    trackNetworkingStatus(checked);
                    store.updateUser({ networking: checked });
                  }}
                  checked={accepted}
                />
              ) : (
                <Button
                  primary
                  as={NavLink}
                  to="/profile"
                  onClick={() => {
                    trackEvent(eventTags.CTA_NETWORKING_ENABLE);
                  }}
                >
                  {getString('profile.enableNetworking')}
                </Button>
              )}
            </Segment.Inline>
          </Segment>
        </Container>
      </CmsPageLayout>
    );
  }

  return (
    <>
      <CmsPageLayout className={cx('page', 'page--cms', 'networking')} pageHeader={pageHeader}>
        <Container>
          {headerBlocks && <CmsBlocks blocks={headerBlocks.blocks || []} />}
          <NetworkingScreen />
        </Container>
      </CmsPageLayout>
    </>
  );
};

ChatShield.defaultProps = {
  headerBlocks: undefined,
  pageHeader: undefined,
};

ChatShield.propTypes = {
  headerBlocks: PropTypes.object,
  pageHeader: PropTypes.object,
};

export default ChatShield;
