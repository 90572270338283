export async function getJson(url, options) {
  const { status: statusHooks, ...rest } = options || {};
  const res = await fetch(url, rest);

  if (statusHooks?.[res.status]) {
    const data = await statusHooks[res.status](await res.json());
    return data;
  }

  return res.json();
}

export async function postJson(url, json, options) {
  const data = await getJson(url, {
    ...options,
    method: 'POST',
    headers: {
      ...options?.headers,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(json),
  });

  return data;
}
