import {
  addMinutes,
  formatDistance,
  isAfter,
  isBefore,
  isToday,
  isTomorrow,
  parseISO,
  subMinutes,
} from 'date-fns';
import padStart from 'lodash/padStart';
import moment from 'moment';
import { debugNowTime } from '../hooks/useAutoRefresh';
import { dateFNSLocale } from './date';

export function isNow({ startDate, endDate }) {
  const now = new Date();

  return (
    isBefore(parseISO(startDate), addMinutes(now, 5)) &&
    isAfter(parseISO(endDate), subMinutes(now, 5))
  );
}
export function isUpcoming({ startDate }) {
  const now = new Date();
  return isAfter(parseISO(startDate), now);
}

export function isFinished({ endDate }) {
  const now = new Date();
  return isBefore(parseISO(endDate), now);
}

export function startsToday({ startDate }) {
  return isToday(parseISO(startDate));
}

export function getWorkshopStatus(workshop) {
  if (isFinished(workshop)) return 'finished';
  if (isNow(workshop)) return 'now-soon';
  if (isTomorrow(parseISO(workshop.startDate))) return 'tomorrow';
  if (isUpcoming(workshop)) return 'upcoming';
  return 'upcoming';
}

export function fromNow(date) {
  const now = debugNowTime || new Date();
  if (!date) return null;
  return formatDistance(parseISO(date), now, {
    addSuffix: true,
    locale: dateFNSLocale(),
  });
}

export function formatTimezoneDate(date, timezone) {
  if (!date) return null;
  if (timezone) return moment(parseISO(date)).tz(timezone);
  return moment(parseISO(date));
}

export function durationInMinutes(startDate, endDate) {
  return Math.floor((parseISO(endDate).getTime() - parseISO(startDate).getTime()) / (60 * 1000));
}

export function roundMinutesToQuarter(minutes) {
  return Math.round(minutes / 15) * 15;
}

function formatDurationMinutes(minutes, t) {
  const hours = Math.floor(minutes / 60);
  const minutesLeft = minutes - hours * 60;
  if (hours > 0) {
    const formattedHours = `${t(`dates.hour`, { count: hours })}`;
    if (minutesLeft === 0) return `${formattedHours}`;
    return `${hours}${t(`dates.simple-hour`)}${padStart(minutesLeft, 2, '0')}`;
  }
  return `${padStart(minutesLeft, 2, '0')} minutes`;
}

export function formatDateRangeDuration(startDate, endDate, t) {
  if (!startDate || !endDate) return null;
  const minutes = durationInMinutes(startDate, endDate);
  return formatDurationMinutes(minutes, t);
}
