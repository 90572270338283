import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import CdnImage from './CdnImage';

function ImageIcon({ className, type, icon, maxHeight, style }) {
  if (!icon) return null;
  if (type === 'icon' && typeof icon === 'string') {
    if (!icon) return null;
    return <Icon name={icon} style={style} className={cx('icon', className)} />;
  }
  if (!icon.uri) return null;
  return (
    <CdnImage
      src={icon}
      className={cx('image-icon', className)}
      maxHeight={maxHeight}
      style={style}
    />
  );
}

ImageIcon.defaultProps = {
  className: '',
  icon: undefined,
  maxHeight: 30,
  style: {},
  type: 'icon',
};

ImageIcon.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  maxHeight: PropTypes.number,
  style: PropTypes.object,
  type: PropTypes.oneOf(['image', 'icon']),
};

export default ImageIcon;
