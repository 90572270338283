/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import ImageIcon from '../../../../components/ImageIcon';
import SquareImage from '../../../../components/SquareImage';
import { useMe } from '../../../../profile/hooks';

type BurgerUserProps = {
  config?: any;
  onDisconnect: any;
};

const BurgerUserDefaultProps = {
  config: {},
};

export const BurgerUser = ({ config, onDisconnect }: BurgerUserProps): JSX.Element | null => {
  const user = useMe();
  const { showUser, logoutIcon = 'log out' } = config;
  if (!showUser) return null;
  const { thumbnail, firstName, lastName } = user;
  return (
    <div className="sidebar--burger-menu__container--header__user">
      {thumbnail && (
        <SquareImage
          as="img"
          src={thumbnail}
          alt={`${firstName} ${lastName}`}
          imageSize={100}
          className="thumbnail"
        />
      )}
      <div>
        <div className="fullname">
          {firstName} {lastName}
        </div>
        <div className="logout" onClick={onDisconnect}>
          <ImageIcon icon={logoutIcon} maxHeight={25} />
          <span>Logout</span>
        </div>
      </div>
    </div>
  );
};

BurgerUser.defaultProps = BurgerUserDefaultProps;

export default BurgerUser;
