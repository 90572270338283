/* eslint-disable import/no-cycle */
import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Card } from 'semantic-ui-react';
import { bem } from '../../../core/design/bem';
import { workshopProptypes } from '../../../propTypes';
import { EntityTags } from '../../../workshops/components/WorkshopTable/WorkshopModal';
import { getClassName } from '../../../workshops/utils';
import CdnImage from '../../CdnImage';
import EntityLink from '../../EntityLink';
import './WorkshopImageOverlayCard.scss';

const css = bem('overlay-wrapper');

const WOrkshopImageOverlayCard = (props) => {
  const { workshop, ...rest } = props;
  const { image, title, workshopStatus } = workshop;
  const { isHighlighted = true, tagFields, tagsConfig = {} } = rest || {};
  const { size, position = 'outside-overlay' } = tagsConfig;
  const hasImage = image && image.uri;

  return (
    <Card
      className={cx(
        'workshop--variant-image-overlay',
        getClassName(workshop),
        `session--${workshopStatus}`,
        { hidden: !isHighlighted },
      )}
      as={EntityLink}
      entity={{ ...workshop, kind: 'workshops' }}
    >
      {/* TODO: placeholder */}
      <CdnImage maxWidth={800} src={hasImage ? image : null} />
      <div className={css({ tagPosition: position })}>
        <div className="overlay">
          <h2>{title}</h2>
          {position === 'inside-overlay' && (
            <EntityTags size={size} tagFields={tagFields} entity={workshop} type="workshop" />
          )}
        </div>
        {position === 'outside-overlay' && (
          <EntityTags size={size} tagFields={tagFields} entity={workshop} type="workshop" />
        )}
      </div>
    </Card>
  );
};

WOrkshopImageOverlayCard.defaultProps = {};

WOrkshopImageOverlayCard.propTypes = {
  workshop: PropTypes.shape(workshopProptypes).isRequired,
};

export default WOrkshopImageOverlayCard;
