import React from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { workshopProptypes } from '../../propTypes';
import ItemGridBlock from '../../components/cms/ItemGridBlock';

function WorkshopBlogsBlock(props) {
  const { item: workshop, field, itemGrid } = props;
  const blogs = get(workshop, field);
  const hasBlogs = blogs?.length > 0;
  if (!hasBlogs) return null;

  return <ItemGridBlock className="workshop__blogs" items={blogs}  {...itemGrid} />;
}

WorkshopBlogsBlock.defaultProps = {
  field: 'blogs',
  itemGrid: {},
};

WorkshopBlogsBlock.propTypes = {
  field: PropTypes.string,
  item: PropTypes.shape(workshopProptypes).isRequired,
  itemGrid: PropTypes.shape({ item: PropTypes.object }),
};

export default WorkshopBlogsBlock;
