import AgendaPage from '../../agenda/pages/AgendaPage';
import Appointments from '../../appointments/Appointments';
import CmsPage from '../../cms/CmsPage';
import RdvOffersScreen from '../../components/rdv/RdvOffersScreen';
import RdvScreen from '../../components/rdv/RdvScreen';
import ExhibitorPage from '../../exhibitors/pages/ExhibitorPage';
import ExhibitorsPage from '../../exhibitors/pages/ExhibitorsPage';
import TabbedExhibitorPage from '../../exhibitors/pages/TabbedExhibitorPage';
import HomeVideo3DX from '../../home/pages/HomeVideo3DX';
import SplashScreen from '../../home/pages/SplashScreen/SplashScreen';
import IframePage from '../../iframe/IframePage';
import NetworkingRouter from '../../networking/NetworkingRouter';
import ParticipantsScreen from '../../participants/ParticipantsScreen';
import ProfilePage from '../../profile/pages/ProfilePage';
import { ProfileScreen } from '../../profile/Profile';
import SpeakersPage from '../../speakers/pages/SpeakersPage';
import Speaker from '../../speakers/Speaker';
import WorkshopSessionPage from '../../workshop-session/pages/WorkshopSessionPage';
import WorkshopPage from '../../workshops/pages/WorkshopPage';
import WorkshopSessionsPage from '../../workshops/pages/WorkshopSessionsPage';
import WorkshopsPage from '../../workshops/pages/WorkshopsPage';
import WorkshopLive from '../../workshops/WorkshopLive';

export const routeComponents = {
  appointments: Appointments,
  'workshop-live': WorkshopLive,
  cms: CmsPage,
  workshops: WorkshopsPage,
  'workshop-page': WorkshopPage,
  'workshop-sessions': WorkshopSessionsPage,
  profile: ProfileScreen,
  'cv-profile': ProfilePage,
  exhibitors: ExhibitorsPage,
  exhibitor: ExhibitorPage,
  agenda: AgendaPage,
  'exhibitor-group': RdvScreen,
  matching: RdvOffersScreen,
  'exhibitor-tabbed': TabbedExhibitorPage,
  'workshop-session': WorkshopSessionPage, // Apec
  'home-video-3dx': HomeVideo3DX,
  'splash-screen': SplashScreen,
  iframe: IframePage,
  speakers: SpeakersPage,
  speaker: Speaker,
  participants: ParticipantsScreen,
};

export const componentRouters = {
  networking: NetworkingRouter,
};
