import { useMount } from 'ahooks';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Grid, Header, List, Segment } from 'semantic-ui-react';
import UserAgendaBlock, { workshopPath } from '../agenda/blocks/UserAgendaBlock';
import { fetchAgenda } from '../agenda/store/agenda.actions';
import { CmsBlocks } from '../cms/CmsScreen';
import CdnImage from '../components/CdnImage';
import ExpiringNavLink from '../components/ExpiringNavLink';
import NotFoundBlock from '../components/NotFoundBlock';
import ScreenIntro from '../components/screens/ScreenIntro';
import SidebarBlocks from '../components/SidebarBlocks';
import WorkshopList from '../components/workshops/WorkshopList';
import { useScreenConfig } from '../config/screens.context';
import MenuFilter from '../core/filter/MenuFilter';
import { generateFilters } from '../core/filter/utils';
import WorkshopsWithRegistrationBlock from '../home/blocks/WorkshopsWithRegistrationBlock';
import store from '../shared/Store';
import { fetchRegistrations } from '../store/actions';
import { getString, getStrings, throttleDispatchFetch } from '../utils';
import { useFilter, useOrderBy, usePreFilter } from '../utils/hooks';
import { fetchWorkshops } from './store/workshops.actions';

const translationPrefix = 'workshops';

const getRegisteredWorkshops = (workshops) => workshops.slice(3, 5);

const getRecommandedWorkshops = (workshops) => [workshops[0], workshops[2]];

export function formatFullTime({ startDate, endDate }) {
  if (!startDate) return null;
  return `${startDate} - ${endDate.split(' ')[1]}`;
}

export const WorkshopItem = ({ workshop, showImage }) => (
  <List.Item>
    {showImage && <CdnImage src={workshop.image.uri} maxWidth={500} />}
    <List.Content>
      <List.Header as={ExpiringNavLink} to={workshopPath(workshop)}>
        {workshop.title}
      </List.Header>
      <List.Description>{formatFullTime(workshop)}</List.Description>
    </List.Content>
  </List.Item>
);

WorkshopItem.defaultProps = {
  showImage: true,
};

WorkshopItem.propTypes = {
  showImage: PropTypes.bool,
  workshop: PropTypes.object.isRequired,
};

const defaultOrder = [{ field: 'startDate', order: 'asc' }];
const defaultGroupBy = { field: 'startDate', type: 'date' };

const defaultLeftSidebar = { width: 4, blocks: [{ _id: 'filters', type: 'filters' }] };
const defaultRightSidebar = undefined;

const throttledFetch = throttleDispatchFetch(() => {
  store.reduxStore.dispatch(fetchWorkshops());
  store.reduxStore.dispatch(fetchRegistrations());
  store.reduxStore.dispatch(fetchAgenda());
});

export const MenuFilterBlock = ({
  prefilteredData,
  fieldsToFilter,
  allFilters,
  setFilters,
  filterConfig,
  pageId,
}) => (
  <MenuFilter
    filterList={generateFilters(prefilteredData, fieldsToFilter, allFilters)}
    filters={allFilters}
    onChange={setFilters}
    filterConfig={filterConfig}
    pageId={pageId}
  />
);

const sidebarBlockComponents = {
  filters: MenuFilterBlock,
  userAgenda: UserAgendaBlock,
};

const gridTemplates = {
  grid: WorkshopList,
  registration: WorkshopsWithRegistrationBlock,
};

const Workshops = ({ mode, match }) => {
  const workshops = useSelector((state) => state.workshops.workshops);
  const { category } = match.params;
  const {
    filters: fieldsToFilter = [],
    gridTemplate = 'grid',
    gridProps,
    preFilters,
    orderBy: orderByConfig = defaultOrder,
    groupBy = defaultGroupBy,
    leftSidebar = defaultLeftSidebar,
    rightSidebar = defaultRightSidebar,
    header,
    footer,
  } = useScreenConfig('workshops');

  useMount(() => throttledFetch());

  // Pre-filter
  const prefilteredData = usePreFilter(workshops, preFilters);

  // Only keep non-empty filters
  const [filteredData, allFilters, setFilters] = useFilter(prefilteredData, fieldsToFilter, {
    category,
  });
  const finalWorkshops = useOrderBy(filteredData, orderByConfig);
  const sharedSidebarProps = { prefilteredData, fieldsToFilter, allFilters, setFilters };
  const GridComponent = gridTemplates[gridTemplate] || gridTemplates.grid;
  return (
    <>
      {header && <CmsBlocks blocks={header.blocks} />}
      <Grid columns="equal" stackable>
        <SidebarBlocks
          sidebar={leftSidebar}
          blockComponents={sidebarBlockComponents}
          sharedProps={sharedSidebarProps}
        />
        {mode === 'grid' && (
          <Grid.Column>
            <ScreenIntro type="workshops" category={category} />
            <GridComponent
              workshopList={finalWorkshops}
              groupBy={groupBy}
              filters={allFilters}
              {...gridProps}
            />
            {finalWorkshops.length === 0 && (
              <NotFoundBlock {...getStrings(`${translationPrefix}.not-found`)} />
            )}
          </Grid.Column>
        )}
        {mode === 'list' && (
          <>
            <Grid.Column>
              <Segment>
                <Header as="h3">
                  {finalWorkshops.length} {getString(`${translationPrefix}.workshops`)}
                </Header>
                <List divided relaxed>
                  {finalWorkshops.map((workshop) => (
                    <WorkshopItem key={workshop._id} workshop={workshop} />
                  ))}
                </List>
              </Segment>
            </Grid.Column>
            <Grid.Column width={4}>
              <Segment>
                <Header as="h3">{getString(`${translationPrefix}.recommended-for-you`)}</Header>
                <List divided relaxed>
                  {getRecommandedWorkshops(workshops).map((workshop) => (
                    <WorkshopItem key={workshop._id} workshop={workshop} />
                  ))}
                </List>
              </Segment>
              <Segment>
                <Header as="h3">{getString(`${translationPrefix}.registered-workshops`)}</Header>
                <List divided relaxed>
                  {getRegisteredWorkshops(workshops).map((workshop) => (
                    <WorkshopItem key={workshop._id} workshop={workshop} />
                  ))}
                </List>
              </Segment>
            </Grid.Column>
          </>
        )}
        <SidebarBlocks
          sidebar={rightSidebar}
          blockComponents={sidebarBlockComponents}
          sharedProps={sharedSidebarProps}
        />
      </Grid>
      {footer && <CmsBlocks blocks={footer.blocks} />}
    </>
  );
};

Workshops.defaultProps = {
  mode: 'grid',
};

Workshops.propTypes = {
  mode: PropTypes.oneOf(['grid', 'list']),
  match: PropTypes.shape({
    params: PropTypes.shape({
      category: PropTypes.string,
    }),
  }).isRequired,
};
export default Workshops;
