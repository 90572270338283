import React from 'react';
import { Modal, Header } from 'semantic-ui-react';
import { bem } from '../../../core/design/bem';
import store from '../../../shared/Store';

const css = bem('FeedbackModal');

type FeedbackModalProps = {
  className?: string;
  feedbackUrl?: string | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  item: Record<string, any>;
  onClose: () => void;
} & typeof FeedbackModalDefaultProps;

const FeedbackModalDefaultProps = {
  className: '',
  feedbackUrl: null,
};

export const FeedbackModal = ({
  item,
  feedbackUrl,
  className,
  onClose,
}: FeedbackModalProps): JSX.Element | null => {
  if (!item || !feedbackUrl) return null;
  return (
    <Modal
      className={css({}).mix(className).toString()}
      open
      basic
      header={item.title}
      closeIcon
      onClose={onClose}
      style={{ maxWidth: 600 }}
    >
      <Modal.Content style={{ height: '85vh', padding: '40px 50px' }}>
        {item.title && (
          <Header as="h4" style={{ color: 'white', marginBottom: 6 }}>
            {item.title}
          </Header>
        )}
        <iframe
          title="Feedback"
          allowTransparency
          src={`${feedbackUrl}&userId=${store.userId}`}
          style={{ border: 0, height: '100%', width: '100%' }}
        />
      </Modal.Content>
    </Modal>
  );
};

FeedbackModal.defaultProps = FeedbackModalDefaultProps;

export default FeedbackModal;
