/* eslint-disable operator-linebreak */
import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Input, Menu } from 'semantic-ui-react';
import './SearchFilter.scss';

const SearchFilter = ({ filter, value, onSelect }) => {
  const { t } = useTranslation();
  return (
    <Menu.Item className={cx('menu-filter--search', filter.dataKey || '')}>
      <Input
        className="SearchFilter--Field"
        placeholder={filter?.placeholder || t('filters.search')}
        value={value || ''}
        autoFocus
        // eslint-disable-next-line no-shadow
        onChange={(_e, { value }) => onSelect(filter, value)}
        icon={
          value
            ? {
                name: 'close',
                style: { cursor: 'pointer', pointerEvents: 'all' },
                onClick: () => onSelect(filter, undefined),
              }
            : 'search'
        }
      />
    </Menu.Item>
  );
};

SearchFilter.defaultProps = {
  value: undefined,
};

SearchFilter.propTypes = {
  value: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  filter: PropTypes.shape({
    placeholder: PropTypes.string,
    fields: PropTypes.arrayOf(PropTypes.string).isRequired,
    dataKey: PropTypes.string,
  }).isRequired,
};

export default SearchFilter;
