import { useSize } from 'ahooks';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import AppHeader from '../../../components/AppHeader';
import ExpiringNavLink from '../../../components/ExpiringNavLink';
import { useConfig } from '../../../config/config.context';
import { bem } from '../../../core/design/bem';
import Styles from '../../../Styles';
import { videoCDN } from '../../../utils/videoUtils';
import './HomeVideo3DX.scss';

const pageCSS = bem('page');

export const CustomPageLayout = ({ className, header, designOverride, children }) => {
  const hasHeader = !!header;

  return (
    <div className={cx(className ? className.toString() : undefined, { headered: hasHeader })}>
      <Styles designOverride={designOverride} />
      {children}
    </div>
  );
};

CustomPageLayout.defaultProps = {
  className: '',
  designOverride: {},
  header: undefined,
};

CustomPageLayout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  designOverride: PropTypes.object,
  header: PropTypes.object,
};

const VideoMenuEntry = ({ containerSize, dimensions, dot, fontSize, label, pageId, title }) => {
  if (!dot) return null;
  const { width: parentWidth, height: parentHeight } = dimensions;
  const { size = 10, position, shadowColor = 'white' } = dot;

  function toX(x) {
    return (x * containerSize.width) / dimensions.width;
  }
  function toY(y) {
    return (y * containerSize.height) / dimensions.height;
  }

  const dotSize = toX(size);
  const shadowSize = 0.5 * dotSize;
  const paddingSize = 40;
  return (
    <ExpiringNavLink
      className={cx('menu_entry', pageId)}
      to={pageId}
      style={{
        left: `${(position.x * 100) / parentWidth}%`,
        top: `${(position.y * 100) / parentHeight}%`,
      }}
    >
      <div
        className="menu_entry__dot"
        style={{
          width: dotSize,
          height: dotSize,
          marginLeft: -dotSize / 2,
          marginTop: -dotSize / 2,
          boxShadow: `0 0 ${shadowSize}px ${shadowSize}px ${shadowColor}`,
        }}
      />
      <div
        className="menu_entry__label"
        style={{
          fontSize: `${toX(fontSize)}px`,
          padding: toX(paddingSize),
          left: toX(label.position.x - paddingSize),
          top: toY(label.position.y - fontSize * 0.3 - paddingSize),
          whiteSpace: 'nowrap',
        }}
      >
        {title}
      </div>
    </ExpiringNavLink>
  );
};

VideoMenuEntry.defaultProps = {
  fontSize: 30,
};
VideoMenuEntry.propTypes = {
  containerSize: PropTypes.shape({ width: PropTypes.string, height: PropTypes.string }).isRequired,
  dimensions: PropTypes.shape({ width: PropTypes.string, height: PropTypes.string }).isRequired,
  dot: PropTypes.shape({
    size: PropTypes.number,
    position: PropTypes.shape({ x: PropTypes.string, y: PropTypes.string }),
    shadowColor: PropTypes.string,
  }).isRequired,
  fontSize: PropTypes.number,
  label: PropTypes.shape({
    position: PropTypes.shape({ x: PropTypes.string, y: PropTypes.string }),
  }).isRequired,
  pageId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

const VideoMenu = ({ dimensions, entries, video }) => {
  const ref = useRef();
  const size = useSize(ref);

  return (
    <div ref={ref} className="page-background__wrapper--16_9">
      <video muted autoPlay loop>
        <source src={videoCDN(video.uri)} type="video/mp4" />
      </video>

      {size.width && size.height && (
        <div className="menu">
          {entries.map((entry) => (
            <VideoMenuEntry
              key={entry._id}
              {...entry}
              containerSize={size}
              dimensions={dimensions}
            />
          ))}
        </div>
      )}
    </div>
  );
};

VideoMenu.defaultProps = {
  entries: [],
  video: {},
};
VideoMenu.propTypes = {
  dimensions: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }).isRequired,
  entries: PropTypes.array,
  video: PropTypes.object,
};
function HomeVideo3DX(props) {
  const { design, header, pageId, videoWithMenu } = props;
  const { lang, languages } = useConfig();
  return (
    <CustomPageLayout
      className={pageCSS({ type: 'HomeVideo3DX', id: pageId })}
      header={header}
      designOverride={design}
    >
      <AppHeader language={lang} languageOptions={languages} />
      <div className="page-background page-background--video">
        <VideoMenu {...videoWithMenu} />
      </div>
    </CustomPageLayout>
  );
}

HomeVideo3DX.defaultProps = {
  design: {},
  header: {},
  id: '',
};

HomeVideo3DX.propTypes = {
  design: PropTypes.object,
  header: PropTypes.object,
  id: PropTypes.string,
  pageId: PropTypes.string.isRequired,
  videoWithMenu: PropTypes.object.isRequired,
};

export default HomeVideo3DX;
