import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Header } from 'semantic-ui-react';
import BlockContainer from '../../components/BlockContainer';
import RatioContainer from '../../components/RatioContainer';
import VideoEmbed from '../../components/VideoEmbed';
import { eventTags } from '../../core/trackers/events';
import { containerTypePropTypes } from '../../propTypes';
import { getString } from '../../utils';
import { useWorkshopTracker } from '../../utils/hooks';
import Images from '../../utils/Images';
import './ReplayBlock.scss';

const translationPrefix = 'workshops.workshop';

export const Replay = ({ item, replay, ...props }) => {
  const onInferredClick = useWorkshopTracker(eventTags.REPLAY_VIDEO_LAUNCHED, item);

  return <VideoEmbed onInferredClick={() => onInferredClick(replay)} item={item} {...props} />;
};

Replay.propTypes = {
  item: PropTypes.object.isRequired,
  replay: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
};

const WorkshopPreReplayPoster = ({ showTitle, item }) => {
  const { t } = useTranslation();
  return (
    <div className="preReplay--poster">
      {showTitle && (
        <Header as="h2" className="title">
          {item.title}
        </Header>
      )}
      <RatioContainer className="preReplay--poster" ratio={16 / 9}>
        <div className="overlay" />
        <Header as="h2" className="text">
          {t(`${translationPrefix}.pre-replay-poster-text`)}
        </Header>
        <img
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          src={Images.maxWidth(item.preReplayPoster?.image || item.image, 1280)}
          alt={item.title || 'poster'}
        />
      </RatioContainer>
    </div>
  );
};

const WorkshopReplayBlock = React.memo(
  ({
    item,
    title: headerTitle,
    field,
    videoProps,
    showPreReplayPoster,
    containerType,
    statsMode,
    ...rest
  }) => {
    const { showTitle } = rest;
    const videos = item[field] || [];
    const hasVideos = videos && videos.length > 0;
    if (!hasVideos && !showPreReplayPoster) return null;
    if (!hasVideos && showPreReplayPoster) {
      return <WorkshopPreReplayPoster item={item} showTitle={showTitle} />;
    }

    // console.log('statsMode', statsMode);

    return (
      <BlockContainer
        className="block--video"
        type={containerType}
        header={headerTitle || getString(`${translationPrefix}.replay`)}
      >
        {videos.map((replay) => {
          const { _id, title, value, description, ...video } = replay;
          const { uri } = value;
          if (!uri) return null;
          return (
            <div key={_id} style={{ marginBottom: 10 }}>
              {(title || (showTitle && item.title)) && (
                <Header as="h2">{title || item.title}</Header>
              )}
              {description && <div style={{ marginBottom: 8 }}>{description}</div>}
              <Replay
                icon="play"
                defaultActive
                replay={replay}
                item={item}
                statsMode={statsMode}
                {...videoProps}
                {...video}
                url={uri}
              />
            </div>
          );
        })}
      </BlockContainer>
    );
  },
);

WorkshopReplayBlock.defaultProps = {
  containerType: 'segment',
  field: 'replays',
  showPreReplayPoster: false,
  title: '',
  videoProps: {},
};

WorkshopReplayBlock.propTypes = {
  containerType: containerTypePropTypes,
  field: PropTypes.string,
  item: PropTypes.object.isRequired,
  showPreReplayPoster: PropTypes.bool,
  title: PropTypes.string,
  videoProps: PropTypes.shape({
    defaultActive: PropTypes.bool,
  }),
};

export default WorkshopReplayBlock;
