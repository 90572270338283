import React from 'react';
import { useMedia } from 'react-media';
import { Grid } from 'semantic-ui-react';

import { bem } from '../../../core/design/bem';
import { GLOBAL_MEDIA_QUERIES } from '../../../utils/mediaQueries';
import BlockContainer from '../../BlockContainer';
import CarouselBlock from '../CarouselBlock';
import CTATileBlock from '../CTATileBlock';
import { ContainerProps, Image } from '../types';
import './CTATilesBlock.scss';

const css = bem('CTATilesBlock');

type CTATileAnimation = 'scale';
type CTATileVariant = 'segment' | 'square';

type CTATileBlockProps = {
  _id: string;
  animation?: CTATileAnimation;
  backgroundImage?: Image;

  icon?: string;
  subtitle?: string;
  title: string;
  to: string;
  variant?: CTATileVariant;
};

type CTATilesBlockProps = {
  _id: string;
  animation?: CTATileAnimation;
  blocks?: CTATileBlockProps[];
  className?: string;
  container?: ContainerProps;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  gridProps?: any;
  imageProps?: Record<string, string>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  itemGridProps?: any;
  itemsPerRow?: number;
  style?: Record<string, string>;
  target?: string;
  variant?: CTATileVariant;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any;
};

const CTATilesBlock = ({
  _id,
  animation,
  variant,
  container,
  blocks,
  className,
  gridProps,
  itemGridProps,
  itemsPerRow,
  imageProps,
  ...rest
}: CTATilesBlockProps): JSX.Element | null => {
  const { desktop } = useMedia({ queries: GLOBAL_MEDIA_QUERIES });
  if (!blocks || blocks.length < 0) return null;
  const { mobile = {}, showMoreButton = false, stretched = false } = rest;
  const { variant: mobileVariant, carousel } = mobile;
  const centeredGrid = itemsPerRow && blocks.length < itemsPerRow;
  const blockClassname = css({ id: _id, variant })
    .mix(className || container?.className)
    .toString();
  if (!desktop && mobileVariant === 'carousel') {
    return (
      <CarouselBlock
        _id={_id}
        carousel={carousel}
        className={blockClassname}
        container={container}
        items={blocks}
        component={(block: any) => (
          <CTATileBlock
            animation={animation}
            variant={variant}
            {...itemGridProps}
            {...block}
            maxImageWidth={itemsPerRow && itemsPerRow >= 2 ? 600 : 1200}
            aspectRatio
            imageProps={imageProps}
            showMoreButton={showMoreButton}
          />
        )}
      />
    );
  }
  return (
    <BlockContainer {...container} className={blockClassname}>
      <Grid
        stretched={stretched}
        className={className}
        doubling
        columns={itemsPerRow}
        centered={centeredGrid}
        {...gridProps}
      >
        {blocks.map((block) => (
          <Grid.Column key={block._id}>
            <CTATileBlock
              animation={animation}
              variant={variant}
              {...itemGridProps}
              {...block}
              maxImageWidth={itemsPerRow && itemsPerRow >= 2 ? 600 : 1200}
              imageProps={imageProps}
              showMoreButton={showMoreButton}
            />
          </Grid.Column>
        ))}
      </Grid>
    </BlockContainer>
  );
};

CTATilesBlock.defaultProps = {
  animation: undefined,
  blocks: [],
  container: {},
  className: undefined,
  imageProps: {},
  itemGridProps: {},
  itemsPerRow: 2,
  gridProps: {},
  target: undefined,
  style: undefined,
  variant: undefined,
};

export default CTATilesBlock;
