import orderBy from 'lodash/orderBy';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Dropdown, Menu } from 'semantic-ui-react';
import Images from '../../../utils/Images';
import { replaceValues } from '../../../utils/stringUtils';
import { useUserCollection } from '../../users/users.hooks';
import './SelectEntity.scss';

function generateThumbnail(image) {
  if (!image) return undefined;
  return { avatar: true, src: Images.square(image, 32) };
}

function computeOptions(options, filter) {
  const { labelTemplate, imageKey } = filter;
  return orderBy(
    options.map((option) => ({
      text: replaceValues(labelTemplate, option),
      key: option._id,
      value: option._id,
      image: generateThumbnail(option[imageKey]),
    })),
    (o) => o.text.toLowerCase(),
  );
}

const SelectEntity = ({ filter, value, onSelect }) => {
  const { title, placeholder } = filter;

  const entities = useUserCollection(filter.dataKey);
  const dropdownOptions = useMemo(() => computeOptions(entities, filter), [entities, filter]);

  return (
    <Menu.Item>
      <Menu.Header>{title}</Menu.Header>
      <Dropdown
        className="select-entity--dropdown"
        fluid
        selection
        placeholder={placeholder}
        labeled
        search
        clearable
        options={dropdownOptions}
        value={value}
        onChange={(e, option) => onSelect(filter, option?.value || undefined)}
      />
    </Menu.Item>
  );
};

SelectEntity.defaultProps = {
  value: undefined,
};

SelectEntity.propTypes = {
  value: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  filter: PropTypes.shape({
    title: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    dataKey: PropTypes.string.isRequired,
    labelTemplate: PropTypes.string,
    imageKey: PropTypes.string,
  }).isRequired,
};

export default SelectEntity;
