import React from 'react';
import PropTypes from 'prop-types';
import { Container, Grid } from 'semantic-ui-react';
import { CmsPageLayout } from '../../cms/CmsPage';
import GridContainer from '../../components/GridContainer';
import { bem } from '../../core/design/bem';
import VerticalMenu from '../../components/VerticalMenu.js';
import UserColoredCalendarBlock from '../../agenda/blocks/UserColoredCalendarBlock';
import WorkshopGroup from '../components/WorkshopGroup';
import { useWorkshopWithSessions } from '../store/workshopSessions.hooks';
import { useSidebarValues } from '../../components/rdv/rdv.hooks';
import { CmsBlocks } from '../../cms/CmsScreen';
import { withScreenClosed } from '../../core/screens/screens.hoc';

const pageBlock = bem('page');

const sidebarComponents = {
  'vertical-menu': VerticalMenu,
  agenda: UserColoredCalendarBlock,
};

function WorkshopSessionPage(props) {
  const { sidebar, header, footer, blocks, metadata, design, pageHeader, pageFooter } = props;
  const workshops = useWorkshopWithSessions();
  const newSidebar = useSidebarValues(sidebar);

  return (
    <CmsPageLayout
      className={pageBlock({
        'workshop-session': true,
      }).toString()}
      metadata={metadata}
      designOverride={design}
      pageHeader={pageHeader}
      pageFooter={pageFooter}
    >
      <Container>
        {header && <CmsBlocks blocks={header.blocks} />}
        <GridContainer sidebar={newSidebar} sidebarComponents={sidebarComponents}>
          <Grid.Column>
            {blocks.map((block) => (
              <WorkshopGroup key={block.key} workshops={workshops} {...block} />
            ))}
          </Grid.Column>
        </GridContainer>
        {footer && <CmsBlocks blocks={footer.blocks} />}
      </Container>
    </CmsPageLayout>
  );
}

WorkshopSessionPage.defaultProps = {
  blocks: [],
  design: {},
  footer: undefined,
  header: undefined,
  metadata: undefined,
  pageFooter: undefined,
  pageHeader: undefined,
  sidebar: {},
};

WorkshopSessionPage.propTypes = {
  blocks: PropTypes.arrayOf(PropTypes.object),
  design: PropTypes.object,
  footer: PropTypes.object,
  header: PropTypes.object,
  metadata: PropTypes.object,
  pageFooter: PropTypes.object,
  pageHeader: PropTypes.object,
  sidebar: PropTypes.object,
};

export default withScreenClosed(WorkshopSessionPage, { className: 'page--workshop-sessions' });
