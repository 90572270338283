import { toast } from 'react-toastify';

import { sweetConfirmAlert, sweetAlert, sweetPrompt } from '../../../utils/popupUtils';
import { getString } from '../../../utils';
import platformService from '../../../core/services/platform.service';
import { eventTags } from '../../../core/trackers/events';
import store from '../../../shared/Store';
import { fetchAppointments } from '../../../store/actions';
import { findOverlappingEvent } from '../../../utils/agendaUtils';
import { getTimeZone } from '../../../utils/date';
import { fetchAgenda } from '../../../agenda/store/agenda.actions';

async function promptMessage(item) {
  const translationPrefix = 'appointments.slots.message-modal';
  const { value } = await sweetPrompt({
    title: getString(`${translationPrefix}.title`, item),
    text: getString(`${translationPrefix}.text`, item),
    inputPlaceholder: getString(`${translationPrefix}.placeholder`, item),
  });
  return value;
}

const translationPrefix = 'appointments';

function getErrorString(code) {
  switch (code) {
    case 'E_MISSING_APPOINTMENT':
      return getString(`${translationPrefix}.missing-appointment`);
    case 'E_UNAVAILABLE_USER':
      return getString(`agenda.unavailable-user`);
    case 'E_NO_AVAILABLE_TIMESLOT':
      return getString(`${translationPrefix}.slots.unavailable-slot`);
    default:
      return getString(`${translationPrefix}.slots.book-appointment-error`);
  }
}

export function showError(code) {
  return sweetAlert({ icon: 'error', title: getErrorString(code) });
}

export function showMessage(message, icon) {
  return sweetAlert({ icon, title: message });
}

export async function showConfirm(title, message) {
  return sweetConfirmAlert({
    title,
    text: message,
    confirmButtonText: getString(`alert.confirm`),
    cancelButtonText: getString(`alert.cancel`),
  });
}
export async function showBookConflictConfirm(event) {
  return showConfirm(
    getString(`${translationPrefix}.slots.force-confirm-title`),
    getString(`${translationPrefix}.slots.force-confirm-message`, {
      name: event.title,
    }),
  );
}

export async function showBookConflict(event) {
  return showMessage(
    getString(`${translationPrefix}.slots.force-confirm-message`, {
      name: event.title,
    }),
    'error',
  );
}

export async function bookAppointment(
  item,
  { startDate, endDate },
  agenda,
  // eslint-disable-next-line no-shadow
  { trackEvent, showMessage },
) {
  const appointmentToBook = {
    startDate,
    endDate,
    groupId: item._id,
  };
  const { timeZone: timezone } = getTimeZone() || {};
  try {
    const overlappingEvent = agenda && findOverlappingEvent(agenda, appointmentToBook);

    const userIsAvailableOrStillWantToBook =
      overlappingEvent == null || (await showBookConflictConfirm(overlappingEvent));
    if (!userIsAvailableOrStillWantToBook) {
      return false;
    }
    let message;
    if (showMessage) {
      message = await promptMessage(item);
      if (message === undefined) {
        // Cancel
        return false;
      }
    }
    const res = await platformService.bookAppointment(item.collection, {
      ...appointmentToBook,
      message,
      timezone,
    });
    if (res.success) {
      // prettier-ignore
      const bookEvent =
          item.collection === 'program' ?
            eventTags.PROGRAM_APPOINTMENT_BOOK :
            eventTags.SPONSOR_APPOINTMENT_BOOK;
      trackEvent(bookEvent, {
        userId: store.userId,
        startDate,
        endDate,
        message,
        item,
      });
      store.reduxStore.dispatch(fetchAppointments());
      store.reduxStore.dispatch(fetchAgenda());
      toast(
        getString(`${translationPrefix}.slots.meeting-confirmed`, {
          name: item.name,
        }),
      );
      return true;
    }

    // Error
    const { errors } = res;
    const { code } = errors[0];
    if (code === 'E_NO_AVAILABLE_TIMESLOT') {
      showError(code);
    } else {
      showError('ERROR');
    }
  } catch (error) {
    showError('ERROR');
  }
  return false;
}

export async function cancelAppointment(appointment, item, { trackEvent }) {
  const confirm = await showConfirm(
    getString(`${translationPrefix}.slots.cancel-confirm`, {
      name: item.name,
    }),
  );
  if (confirm) {
    try {
      const res = await platformService.cancelAppointment(item.collection, appointment._id);
      if (res.errors) {
        const { code } = res.errors[0];
        if (code === 'E_MISSING_APPOINTMENT') {
          showError(code);
        } else {
          showError('ERROR');
        }
      } else if (trackEvent) {
        // prettier-ignore
        const unbookEvent =
          item.collection === 'program' ?
            eventTags.PROGRAM_APPOINTMENT_CANCEL :
            eventTags.SPONSOR_APPOINTMENT_CANCEL;
        trackEvent(unbookEvent, {
          userId: store.userId,
          startDate: appointment.startDate,
          item,
        });
      }
    } catch (error) {
      console.error(error);
      showError('ERROR');
    } finally {
      store.reduxStore.dispatch(fetchAppointments()); // refresh user appointments
      store.reduxStore.dispatch(fetchAgenda());
    }
  }
}
