/* eslint-disable operator-linebreak */
import { useSetState } from 'ahooks';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';
import Highlighter from 'react-highlight-words';
import 'react-circular-progressbar/dist/styles.css';
import { Grid, Input, Item, Pagination } from 'semantic-ui-react';

import Details from './Details';
import './ListDetails.scss';

import CdnImage from '../CdnImage';
import ExpiringNavLink from '../ExpiringNavLink';
import ProgressCircular from '../ProgressCircular';

import { bem } from '../../core/design/bem';
import { applySearchFilter, searchAsWordList } from '../../core/filter/utils';
import useTranslations from '../../hooks/useTranslations';
import { replaceValues } from '../../utils/stringUtils';
import { eventTags } from '../../core/trackers/events';
import store from '../../shared/Store';
import { useTracking } from '../../Context';

const cxList = bem('list-details');
const cxListItems = bem(cxList('items').toString());
const cxListItem = bem(cxListItems('item').toString());

const ListItem = ({ item, config, onClick, isActive, searchValue }) => {
  const { t } = useTranslations('components.list-details');
  const {
    image: imageField = 'image',
    title: titleTemplate = '{title}',
    subtitle: subtitleTemplate,
    extra: extraTemplate,
    percentage: percentageField = 'percentage',
    urlPrefix,
  } = config;
  const image = get(item, imageField);
  const appointment = get(item, 'appointment');
  const title = replaceValues(titleTemplate, item);
  const subtitle = replaceValues(subtitleTemplate, item);
  const extra = replaceValues(extraTemplate, item);
  const percentage = get(item, percentageField);
  const searchWords = searchAsWordList(searchValue);
  const { trackEvent } = useTracking();
  return (
    <Item
      className={cxListItem.state({ active: isActive }).toString()}
      onClick={() => {
        onClick(item);
        trackEvent(eventTags.LIST_DETAIL_ITEMS, {
          userId: store.userId,
          item,
        });
      }}
      as={urlPrefix ? ExpiringNavLink : undefined}
      to={urlPrefix ? `${urlPrefix}/${item._id}` : undefined}
    >
      {image && image.uri && (
        <CdnImage
          src={image}
          className={cxListItem('image').toString()}
          maxWidth={60}
          maxHeight={50}
          func="fit"
          cdnOptions={{ bg_color: 'transparent' }}
        />
      )}
      <div className={cxListItem('content')}>
        {title && (
          <h4 className={cxListItem('title')}>
            <Highlighter
              className="highlighter"
              searchWords={searchWords}
              autoEscape
              textToHighlight={title}
            />
          </h4>
        )}
        {subtitle && (
          <p className={cxListItem('subtitle')}>
            <Highlighter
              className="highlighter"
              searchWords={searchWords}
              autoEscape
              textToHighlight={subtitle}
            />
          </p>
        )}
        {extra && <p className={cxListItem('extra')}>{extra}</p>}
      </div>
      {percentage != null && (
        <div className={cxListItem('progress')}>
          <ProgressCircular value={percentage} config={config} />
          {appointment && <div className="appointment">{t('appointment')}</div>}
        </div>
      )}
    </Item>
  );
};

ListItem.defaultProps = {
  isActive: false,
  config: {},
  searchValue: '',
};

ListItem.propTypes = {
  config: PropTypes.object,
  isActive: PropTypes.bool,
  item: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  searchValue: PropTypes.string,
};

const ListDetails = (props) => {
  const { items, config, selectedId } = props;
  const { t } = useTranslations('components.list-details');
  const { maxItems, maxHeight, search = {} } = get(config, 'items', {});
  const { fields: searchFields = [], placeholder } = search;
  const totalPages = Math.ceil(items.length / (maxItems || 1));
  const index = items.findIndex((i) => selectedId === i._id);
  const defaultActivePage = index === -1 ? 1 : Math.ceil((index + 1) / maxItems);
  const [state, setState] = useSetState({
    selectedItem: selectedId || items[0]?._id || undefined,
    activePage: defaultActivePage,
    searchText: '',
  });
  const { trackEvent } = useTracking();
  if (!selectedId) {
    const firstItem = items?.length > 0 && items[0];
    trackEvent(eventTags.LIST_DETAIL_ITEMS, {
      userId: store.userId,
      item: firstItem,
    });
  }
  const { selectedItem, activePage, searchText } = state;
  const startIndex = (activePage - 1) * maxItems;
  const filteredItems = applySearchFilter(items, searchText, searchFields);
  const pageItems = maxItems
    ? filteredItems.slice(startIndex, startIndex + maxItems)
    : filteredItems;
  const selected = filteredItems.find((i) => selectedItem === i._id);

  return (
    <Grid className={cxList()}>
      <Grid.Column width={6}>
        <div className={cxList('header')}>
          <h3>{config.header?.title || ''}</h3>
          {filteredItems.length} {t('results')}
        </div>
        {searchFields.length > 0 && (
          <div className={cxList('search')}>
            <Input
              fluid
              autoFocus
              placeholder={placeholder || t('search')}
              onChange={(e) => setState({ searchText: e.target.value })}
              value={searchText}
              style={{ borderRadius: 20 }}
              icon={
                searchText
                  ? {
                      name: 'close',
                      style: { cursor: 'pointer', pointerEvents: 'all' },
                      onClick: () => setState({ searchText: '' }),
                    }
                  : undefined
              }
            />
          </div>
        )}
        <div className={cxList('items')} style={{ maxHeight }}>
          {pageItems.map((item) => {
            const isActive = selectedItem === item._id;
            return (
              <ListItem
                isActive={isActive}
                key={item._id}
                item={item}
                config={config.items}
                onClick={(i) => setState({ selectedItem: i._id })}
                searchValue={searchText}
              />
            );
          })}
        </div>
        {maxItems && totalPages > 1 && (
          <Pagination
            className={cxList('paginator').toString()}
            boundaryRange={0}
            ellipsisItem={null}
            firstItem={null}
            lastItem={null}
            siblingRange={2}
            defaultActivePage={defaultActivePage}
            totalPages={totalPages}
            pointing
            secondary
            onPageChange={(_e, data) => {
              const { activePage: i } = data;
              setState({ activePage: i });
            }}
          />
        )}
      </Grid.Column>
      <Grid.Column stretched width={10}>
        {selected && <Details item={selected} config={config} />}
      </Grid.Column>
    </Grid>
  );
};

ListDetails.defaultProps = {
  config: {},
  items: [],
  selectedId: undefined,
};

ListDetails.propTypes = {
  config: PropTypes.object,
  items: PropTypes.arrayOf(PropTypes.object),
  selectedId: PropTypes.string,
};

export default ListDetails;
