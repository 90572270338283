import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { Button, Container, Grid, Header, Icon } from 'semantic-ui-react';
import { CmsBlocks } from '../cms/CmsScreen';
import Blocks from '../components/Blocks';
import CdnImage from '../components/CdnImage';
import EntityLink from '../components/EntityLink';
import Footer from '../components/Footer';
import PageBackground from '../components/PageBackground';
import SidebarBlocks from '../components/SidebarBlocks';
import { useConfig } from '../config/config.context';
import { useDesignConfig } from '../config/design.context';
import { useScreenConfig } from '../config/screens.context';
import useTranslations from '../hooks/useTranslations';
import { HorizontalMenu } from '../Layouts/Default/components/HorizontalMenu';
import { imageProptypes, workshopProptypes } from '../propTypes';
import Styles from '../Styles';
import { useStoreVersionSync } from '../utils/useVersionSync';
import { mainBlockComponents, sidebarBlockComponents } from './blocks';
import { fetchWorkshop } from './store/workshops.actions';
import { useWorkshop } from './store/workshops.hooks';
import { defaultMainBlocks, defaultRightBlocks } from './Workshop';
import './WorkshopLive.scss';

const translationPrefix = 'workshops.workshop';

const headerProps = {
  backgroundColor: PropTypes.string,
  backgroundImage: imageProptypes,
  logo: imageProptypes,
  rightLogo: imageProptypes,
};

const LivePageHeader = ({ logo, rightLogo, backgroundColor, backgroundImage }) => {
  if (backgroundImage) {
    return <div>Background image NYI</div>;
  }
  return (
    <div className="header--fixed" style={{ backgroundColor }}>
      <div className="container">
        {logo && <CdnImage className="logo" maxHeight={100} src={logo} as="img" />}
        {rightLogo && <CdnImage className="rightLogo" maxHeight={140} src={rightLogo} as="img" />}
      </div>
    </div>
  );
};

LivePageHeader.defaultProps = {
  logo: undefined,
  rightLogo: undefined,
  backgroundColor: undefined,
  backgroundImage: undefined,
};
LivePageHeader.propTypes = headerProps;

const LivePageLayout = ({ className, header, designOverride, children }) => {
  const design = useDesignConfig();
  const { menu } = useConfig();
  const hasHeader = !!header;
  const { background } = designOverride; // page design
  const { color: backgroundColor, image: backgroundImage } = background || {};
  return (
    <div className={cx(className, { headered: hasHeader })}>
      {designOverride && <Styles designOverride={designOverride} />}
      <PageBackground
        {...background}
        image={backgroundImage || (!backgroundColor && design.homeBackground)}
      />
      {header && <LivePageHeader {...header} />}
      <Container>
        <div style={{ paddingBottom: 10 }}>{menu && <HorizontalMenu menu={menu} />}</div>
        {children}
      </Container>
      <Footer />
    </div>
  );
};

LivePageLayout.defaultProps = {
  className: '',
  designOverride: {},
  header: undefined,
};

LivePageLayout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  designOverride: PropTypes.object,
  header: PropTypes.object,
};

function WorkshopLive(props) {
  const { workshop, pageId } = props;
  const { t } = useTranslations();
  useStoreVersionSync({
    id: workshop?._id,
    collection: workshop?.collection,
    actualVersion: workshop?.version,
    resourceType: 'workshops',
    fetcher: fetchWorkshop,
  });
  const {
    header,
    layout = 'default',
    mainBlocks = defaultMainBlocks,
    parentPath,
    sidebarBlocks = defaultRightBlocks,
  } = useScreenConfig(pageId) || {};
  // console.log('screenConfig', useScreenConfig(pageId));
  const { title, shortDescription, description, page = {}, pageHidden } = workshop;
  if (pageHidden) return <Redirect to="/" />;
  return (
    <LivePageLayout
      className={`page page--workshop-live workshop--${workshop._id} workshop--${workshop.slug}`}
      header={page.header}
      designOverride={page.design}
    >
      {layout === 'default' && (
        <Button
          className="back-button"
          as={EntityLink}
          entity={{ parentPath, kind: 'workshops' }}
          // Passe a undefined je n'arrive pas à saisir pourquoi...
          // dans le même temps la collection change également c'est la catégorie qui est prise
          style={{ backgroundColor: 'transparent', paddingLeft: 0 }}
        >
          <Icon name="angle left" /> {t(`${translationPrefix}.back-to-workshops`)}
        </Button>
      )}

      {header && <CmsBlocks blocks={header.blocks} />}

      {layout === 'menu' && (
        <div className="workshop--details">
          <Header className="workshop--details__title" as="h2">
            {title}
          </Header>
          {!description && shortDescription && (
            <p className="workshop--details__shortDescription">{shortDescription}</p>
          )}
          {description && (
            <p
              className="workshop--details__description"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: description }}
            />
          )}
        </div>
      )}
      <Grid className="workshop--blocks">
        <Grid.Column width={12}>
          <Blocks
            blocks={mainBlocks}
            sharedProps={{
              item: workshop,
              translationPrefix,
            }}
            blockComponents={mainBlockComponents}
          />
        </Grid.Column>
        <SidebarBlocks
          sidebar={{ width: 4, blocks: sidebarBlocks }}
          blockComponents={sidebarBlockComponents}
          sharedProps={{
            item: workshop,
            translationPrefix,
          }}
        />
      </Grid>
    </LivePageLayout>
  );
}

WorkshopLive.defaultProps = {
  pageId: 'live',
  workshop: undefined,
};

WorkshopLive.propTypes = {
  pageId: PropTypes.string,
  workshop: PropTypes.shape(workshopProptypes),
};

export default (props) => {
  const workshop = useWorkshop(props);
  if (!workshop) return null; // 404 ? Wait for loading ?
  return <WorkshopLive {...props} workshop={workshop} />;
};
