import PropTypes from 'prop-types';
import React from 'react';

function generateBlockKey(block, blocks) {
  if (block.key) return block.key;
  if (block._id) return block._id;
  const indexBasedOnType = blocks.filter(({ type }) => type === block.type).indexOf(block);
  return `${block.type}-${indexBasedOnType}`;
}
const Blocks = ({ blocks, blockComponents, sharedProps, blockWrapper }) => {
  if (!blocks || blocks.length === 0) return null;
  return (
    <>
      {blocks.map((block) => {
        const { type, visible = true } = block;
        if (!visible) return null;
        const Component = blockComponents[type];
        if (!Component) {
          console.warn('Unknown block type', block);
          return null;
        }
        const element = (
          <Component key={generateBlockKey(block, blocks)} {...sharedProps} {...block} />
        );
        if (blockWrapper) {
          return blockWrapper(element);
        }
        return element;
      })}
    </>
  );
};

Blocks.defaultProps = {
  sharedProps: undefined,
  blocks: [],
  blockWrapper: undefined,
};

Blocks.propTypes = {
  blockComponents: PropTypes.object.isRequired,
  blocks: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
    }),
  ),
  blockWrapper: PropTypes.func,
  sharedProps: PropTypes.object,
};

export default Blocks;
