import React from 'react';
import PropTypes from 'prop-types';
import SpeakersBlock from '../../components/speakers/SpeakersBlock';

const WorkshopSpeakersBlock = (props) => {
  const { item, blockConfig, field, header } = props;

  const entries = item[field];
  if (!entries || entries.length === 0) return null;

  return <SpeakersBlock header={header} ids={entries} {...blockConfig} />;
};

WorkshopSpeakersBlock.defaultProps = {
  blockConfig: { openMode: 'link' },
  header: undefined,
  field: 'speakers',
};

WorkshopSpeakersBlock.propTypes = {
  blockConfig: PropTypes.object,
  item: PropTypes.object.isRequired,
  field: PropTypes.string,
  header: PropTypes.string,
};

export default WorkshopSpeakersBlock;
