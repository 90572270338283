/* eslint-disable react/prop-types */
import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from 'semantic-ui-react';
import EntityLink from '../../../../../components/EntityLink';
import { bem } from '../../../../../core/design/bem';
import { useMe } from '../../../../../profile/hooks';
import { durationInMinutes, roundMinutesToQuarter } from '../../../../../utils/dateUtils';
import { useWorkshop } from '../../../../../workshops/store/workshops.hooks';
import './AgendaDay.scss';

const block = bem('AgendaDay');
const sessionBlock = bem('AgendaSession');

function getEntityForSession(session, workshop) {
  if (session.type === 'appointment') {
    // Fetch parent
    return { kind: 'users', ...session.group };
  }
  if (session.type === 'session') {
    // Fetch parent
    // TODO: auto-inject from agenda ?
    return { kind: 'workshops', ...workshop };
  }
  return session; // Workshop
}

function roundedDuration(startDate, endDate) {
  if (endDate < startDate) return 0;
  const duration = durationInMinutes(startDate, endDate);
  return roundMinutesToQuarter(duration);
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Session = ({ session, mode, nextDate }) => {
  const { t } = useTranslation();
  const user = useMe();
  const workshop = useWorkshop({ workshopId: session.workshopId });
  const tag = t(`workshops.collections.${session.collection}.tag`, '');
  const booked = (session.slug && user[session.slug]) || false;
  return (
    <>
      <div
        className={sessionBlock({
          collection: session.collection,
          category: session.category,
          minutes: `${roundedDuration(session.startDate, session.endDate)}`,
        })
          .is({ booked })
          .toString()}
      >
        <div className={sessionBlock('time').toString()}>
          {t('components.day-calendar.start-time', { startDate: session.startDate })}
        </div>
        <EntityLink
          entity={getEntityForSession(session, workshop)}
          className={sessionBlock('content').toString()}
        >
          {session.location && (
            <div className={sessionBlock('location').toString()}>
              <Icon name="map marker alternate" />
              {session.location}
            </div>
          )}
          {tag && <div className={sessionBlock('tag').toString()}>{tag}</div>}
          <div className={sessionBlock('title').toString()}>{session.title}</div>
          {session.shortDescription && (
            <div className={sessionBlock('shortDescription').toString()}>
              {session.shortDescription}
            </div>
          )}
          <div className={sessionBlock('time').toString()}>
            {t('components.day-calendar.time', session)}
          </div>
          {booked && <div className={sessionBlock('booked').toString()}>Booked</div>}
        </EntityLink>
        {mode === 'variable-height' && session.endDate !== nextDate && (
          <div className={sessionBlock('time').toString()}>
            {t('components.day-calendar.start-time', { startDate: session.endDate })}
          </div>
        )}
      </div>
      {mode === 'variable-height' && nextDate && session.endDate !== nextDate && (
        <div
          className={sessionBlock('wait-time', {
            minutes: `${roundedDuration(session.endDate, nextDate)}`,
          }).toString()}
        />
      )}
    </>
  );
};

const AgendaDay = ({ index, day, mode, events }) => {
  const { t } = useTranslation();
  const date = moment(day, 'YYYY-MM-DD');
  const sessions = uniqBy(orderBy(events, 'startDate'), '_id');

  return (
    <div className={block({ day, mode }).toString()}>
      <div className={block('header').toString()}>
        <Icon name="calendar check outline" />
        {t('components.day-calendar.day-header', { date, index, number: index + 1 })}
      </div>
      <div className={block('content').toString()}>
        {sessions.map((session, sessionIndex) => (
          <Session
            key={session._id}
            mode={mode}
            session={session}
            nextDate={sessions[sessionIndex + 1]?.startDate}
          />
        ))}
      </div>
    </div>
  );
};

export default AgendaDay;
