import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Card } from 'semantic-ui-react';
import { upcomingEvents } from '../../components/DayCalendar';
import DaysContainer from '../../components/DaysContainer';
import { useAutoRefresh } from '../../hooks/useAutoRefresh';
import { workshopArrayProptypes } from '../../propTypes';
import { groupEventsByDays } from '../../utils/agendaUtils';
import { useOrderBy } from '../../utils/hooks';
import { useCloudTvSessions } from './CloudTvBlock/cloudtv.hooks';
import WorkshopGridItem from './workshop-templates/WorkshopGridItem';
import WorkshopImageItem from './workshop-templates/WorkshopImageItem';
import WorkshopLightItem from './workshop-templates/WorkshopLightItem';

const templates = {
  light: WorkshopLightItem,
  image: WorkshopImageItem,
  grid: WorkshopGridItem,
};

const defaultOrder = [
  { field: 'startDate', order: 'asc' },
  { field: 'order', order: 'asc' },
];

const WorkshopsContainer = ({ template, children, style }) => {
  const isGrid = template === 'grid';
  const className = cx('WorkshopsRegistrationBlock', template);
  if (isGrid) {
    return (
      <Card.Group className={className} itemsPerRow={3} stackable centered style={style}>
        {children}
      </Card.Group>
    );
  }
  return (
    <div className={className} style={style}>
      {children}
    </div>
  );
};

WorkshopsContainer.defaultProps = {
  style: {},
};

WorkshopsContainer.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
  template: PropTypes.string.isRequired,
};

function getWorkshops(config = {}, workshops, now) {
  const { showOnlyUpcomingEvents, upcomingEventsCount = 3 } = config;
  if (!showOnlyUpcomingEvents) return workshops;
  return upcomingEvents(workshops, now).slice(0, upcomingEventsCount);
}

function WorkshopsWithRegistrationBlock(props) {
  // TO KNOW: config replaced by itemPros to follow general items config
  //  At this time only sephora and capgemini have used this block so changes should be ok everywhere
  const { itemProps: config, collection, template, workshopList } = props;
  let workshops = useCloudTvSessions({ mode: 'workshops' });
  const { groupByDays = true, orderBy: orderByConfig = defaultOrder } = config || {};
  const Component = templates[template];
  if (collection) {
    workshops = workshops.filter((ws) => ws.collection === collection);
  }
  const orderedWorkshops = useOrderBy(workshops, orderByConfig);
  const now = useAutoRefresh(1000);
  const finalWorkshops = getWorkshops(config, orderedWorkshops, now);

  const { days, eventsByDay } = groupEventsByDays(workshopList || finalWorkshops);
  if (groupByDays) {
    return (
      <div className="WorkshopsWithRegistrationBlock" style={{ padding: '20px 10px' }}>
        {days.map((day) => {
          const dayEvents = eventsByDay[day] || [];
          return (
            <div key={day}>
              <DaysContainer days={[day]} />
              <WorkshopsContainer template={template} style={{ marginBottom: 10 }}>
                {dayEvents.map((workshop) => (
                  <Component key={workshop._id} workshop={workshop} config={config} />
                ))}
              </WorkshopsContainer>
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <WorkshopsContainer
      className="WorkshopsWithRegistrationBlock"
      template={template}
      style={{ padding: '20px 10px' }}
    >
      {(workshopList || finalWorkshops).map((workshop) => (
        <Component key={workshop._id} workshop={workshop} config={config} />
      ))}
    </WorkshopsContainer>
  );
}

WorkshopsWithRegistrationBlock.defaultProps = {
  collection: undefined,
  itemProps: {},
  template: 'image',
  workshopList: undefined,
};

WorkshopsWithRegistrationBlock.propTypes = {
  collection: PropTypes.string,
  itemProps: PropTypes.object,
  template: PropTypes.oneOf(['light', 'image', 'grid']),
  workshopList: workshopArrayProptypes,
};

export default WorkshopsWithRegistrationBlock;
