import React, { useState } from 'react';
import { Icon, Menu, SemanticICONS, SemanticWIDTHSNUMBER } from 'semantic-ui-react';
import { bem } from '../../../core/design/bem';

const videoSizer = bem('VideoSizer');

type SidebarTab = {
  key: string;
  field: string;
  title: string;
  icon?: SemanticICONS;
  url?: string;
};

type VideoSidebarTabsProps = {
  tabs: SidebarTab[];
  activeIndex: number;
  onChange: (tabIndex: number) => void;
};

type VideoSidebarProps = {
  open: boolean;
  tabsAlwaysVisible?: boolean;
  tabs: SidebarTab[];
};

const VideoSidebarTabs = ({ tabs, activeIndex, onChange }: VideoSidebarTabsProps): JSX.Element => {
  return (
    <Menu attached="bottom" widths={tabs.length as SemanticWIDTHSNUMBER}>
      {tabs.map((tab, index) => (
        <Menu.Item
          key={tab.key || index}
          name={tab.key || tab.title}
          active={index === activeIndex}
          onClick={() => onChange(index)}
        >
          {tab.icon ? <Icon name={tab.icon} /> : tab.title}
        </Menu.Item>
      ))}
    </Menu>
  );
};

const VideoSidebar = ({ open, tabsAlwaysVisible, tabs }: VideoSidebarProps): JSX.Element | null => {
  const [tabIndex, setTab] = useState(0);
  if (tabs.length === 0) return null;
  const safeTabIndex = tabIndex >= tabs.length ? 0 : tabIndex;
  const tab = tabs[safeTabIndex];

  return (
    <div className={videoSizer('tab', { showTab: open, hasTabs: tabs.length > 1 }).toString()}>
      {(tabs.length > 1 || tabsAlwaysVisible) && (
        <VideoSidebarTabs tabs={tabs} activeIndex={safeTabIndex} onChange={setTab} />
      )}
      {tab && (
        <iframe
          key={tab.key || tab.url}
          className="block--video__qa"
          title={tab.title}
          src={tab.url}
        />
      )}
    </div>
  );
};

VideoSidebar.defaultProps = {
  tabsAlwaysVisible: false,
};

export default VideoSidebar;
