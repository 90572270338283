import cx from 'classnames';
import { partition } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Redirect } from 'react-router-dom';
import { Dropdown, Menu, Search } from 'semantic-ui-react';
import { NetworkMessagesLabel } from '../../networking/components/NetworkNotificationsLabel';
import store from '../../shared/Store';
import { SocialWallLabel } from '../../social-wall/SocialWallLabel';
import ExpiringNavLink from '../ExpiringNavLink';
import ImageIcon from '../ImageIcon';
import LogoutButton from './LogoutButton';
import NavMenuItem from './NavMenuItem';

const menuModesClassNames = {
  classic: '',
  buttons: 'secondary',
  tabs: 'secondary pointing',
};

class AppMenu extends PureComponent {
  handleDisconnect = async () => {
    await store.disconnect();
    this.forceUpdate();
    window.location.reload();
  };

  renderParentItem = ({ _id, label, name, icon, path, type, ...rest }) => {
    const { menuConfig } = this.props;
    const { type: iconType, style, maxHeight } = menuConfig?.icon || {};
    const onClick = type === 'logout' ? this.handleDisconnect : undefined;
    return (
      <NavMenuItem
        {...rest}
        className={cx(name, type)}
        to={path}
        key={_id || name}
        name={name}
        exact={path === '/'}
        onClick={onClick}
      >
        <ImageIcon
          icon={icon}
          type={iconType}
          style={{ marginRight: 8, ...style }}
          maxHeight={maxHeight}
        />
        {label}
        {type === 'search-field' && <Search />}
        {(type === 'networking' || name === 'networking') && <NetworkMessagesLabel color="" />}
        {type === 'social-wall' && <SocialWallLabel color="blue" />}
      </NavMenuItem>
    );
  };

  renderChildrenItem = ({ children = [], label, path, name }) => {
    return (
      // TODO => open on focus for these item !!!!
      <Dropdown
        item
        text={label}
        to={path}
        key={name}
        as={path ? ExpiringNavLink : undefined}
        open={false}
      >
        <Dropdown.Menu>{children && children.map((c) => this.renderItem(c))}</Dropdown.Menu>
      </Dropdown>
    );
  };

  renderItem = (menuItem) => {
    if (menuItem.children) {
      return this.renderChildrenItem(menuItem);
    }
    return this.renderParentItem(menuItem);
  };

  render() {
    const { menu, menuConfig, variant, ...props } = this.props;

    if (store.mustRedirectToLogin()) {
      // Handle disconnect
      return <Redirect to="/login" />;
    }

    const [left, right] = partition(menu, (m) => m.position !== 'right');
    const hasExplicitLogout = menu.some((item) => item.type === 'logout');

    return (
      <Menu
        className={cx(menuModesClassNames[variant], 'menu--main menu--hover')}
        vertical={menuConfig?.wrapper?.variant === 'leftSidebar'}
        {...props}
      >
        {left.map((m) => this.renderItem(m))}

        <Menu.Menu position="right">
          {right.map((m) => this.renderItem(m))}
          {!hasExplicitLogout && (
            <LogoutButton menuConfig={menuConfig} onClick={this.handleDisconnect} />
          )}
        </Menu.Menu>
      </Menu>
    );
  }
}

AppMenu.defaultProps = {
  menu: [],
  menuConfig: {},
  variant: 'classic',
};

AppMenu.propTypes = {
  menu: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.string,
      path: PropTypes.string,
      color: PropTypes.string,
      translations: PropTypes.object,
    }),
  ),
  menuConfig: PropTypes.shape({
    thumbnailButton: PropTypes.bool,
    icon: PropTypes.object,
    wrapper: PropTypes.object,
  }),
  variant: PropTypes.oneOf(['classic', 'tabs', 'buttons']),
};

export default AppMenu;
