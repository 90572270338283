import { get } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { Container, Grid } from 'semantic-ui-react';

import ExhibitorGroup from '../ExhibitorGroup';
import GridContainer from '../GridContainer';
import VerticalMenu from '../VerticalMenu.js';

import { useScreenConfig } from '../../config/screens.context';
import { useExhibitorsWithMatching } from '../../matching/store/matching.hooks';
import { useSidebarValues } from './rdv.hooks';
import UserColoredCalendarBlock from '../../agenda/blocks/UserColoredCalendarBlock';
import { CmsPageLayout } from '../../cms/CmsPage';
import { bem } from '../../core/design/bem';
import { withScreenClosed } from '../../core/screens/screens.hoc';

const css = bem('page');

const components = {
  simple: ExhibitorGroup,
  matching: (props) => <ExhibitorGroup {...props} template="matching" />,
};

const sidebarComponents = {
  'vertical-menu': VerticalMenu,
  agenda: UserColoredCalendarBlock,
};

function RdvScreen({ pageId }) {
  const {
    blocks = [],
    sidebar,
    collection = 'exhibitors',
    scoreCriteria = 'positive',
    metadata,
    design,
    pageHeader,
    pageFooter,
  } = useScreenConfig(pageId);

  const users = useExhibitorsWithMatching(collection, scoreCriteria);
  const newSidebar = useSidebarValues(sidebar);

  return (
    <CmsPageLayout
      className={css({
        'exhibitor-group': true,
      }).toString()}
      metadata={metadata}
      designOverride={design}
      pageHeader={pageHeader}
      pageFooter={pageFooter}
    >
      <Container>
        <GridContainer sidebar={newSidebar} sidebarComponents={sidebarComponents}>
          <Grid.Column>
            {blocks.map((block) => {
              const { type = 'simple', key, ...rest } = block;
              const Component = get(components, type);
              return <Component key={key} items={users} {...rest} />;
            })}
          </Grid.Column>
        </GridContainer>
      </Container>
    </CmsPageLayout>
  );
}

RdvScreen.defaultProps = {
  pageId: 'rdv',
};

RdvScreen.propTypes = {
  pageId: PropTypes.string,
};

export default withScreenClosed(RdvScreen, { className: 'page--exhibitor-group' });
