import React from 'react';
import { Button, Header, Icon } from 'semantic-ui-react';
import AccessMeetingRoomBlock from '../../sponsors/blocks/BookAppointmentBlock/AccessMeetingRoomBlock';
import { getString } from '../../utils';
import { localeFormat } from '../../utils/date';
import { AddAppointmentToCalendar } from '../../workshops/blocks/WorkshopAddToCalendarBlock';
import { StepContainer } from '../components/Step';
import { useStepContext } from './StepContext';

const translationPrefix = 'appointments.steps.prepare';
const t = (key, data) => getString(`${translationPrefix}.${key}`, data);

export const StepPrepareSidebar = () => {
  const { currentAppointment, appointmentUserRef, onCancel, onUpdate } = useStepContext();
  if (!currentAppointment) return null;
  return (
    <div>
      <StepContainer label={<Icon name="check" />} top success>
        <Header as="h4">{t('congrats')}</Header>
        <p>{t('side-note', { date: localeFormat(currentAppointment.startDate, 'PPPPp') })}</p>
        {currentAppointment && appointmentUserRef && (
          <AddAppointmentToCalendar
            appointment={currentAppointment}
            sponsor={appointmentUserRef}
            primary
          />
        )}
        <div style={{ height: '2em' }} />
        <Button className="link" size="small" style={{ display: 'block' }} onClick={onUpdate}>
          {getString('btn.edit')}
        </Button>
        <p style={{ marginTop: '1em' }}>
          <Button className="link" size="small" style={{ display: 'block' }} onClick={onCancel}>
            {getString('btn.cancel')}
          </Button>
        </p>
      </StepContainer>
    </div>
  );
};

export const StepPrepareContent = () => {
  const { currentAppointment } = useStepContext();
  if (!currentAppointment) return null;
  return (
    <div>
      <Header as="h3">
        {t('header', { date: localeFormat(currentAppointment.startDate, 'PPPPp') })}
      </Header>
      <p>{t('subheader')}</p>
      <AccessMeetingRoomBlock appointment={currentAppointment} />
    </div>
  );
};
