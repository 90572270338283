/* eslint-disable react/no-danger */
import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import BlockContainer from '../BlockContainer';

import { useMe } from '../../profile/hooks';
import { containerTypePropTypes } from '../../propTypes';
import { replaceValues } from '../../utils/stringUtils';

function HtmlBlock(props) {
  const { title, content, containerType, _id } = props;
  const user = useMe();
  if (!content) return null;
  return (
    <BlockContainer type={containerType} header={title} className={cx('block--html', _id)}>
      <div dangerouslySetInnerHTML={{ __html: replaceValues(content, { user }) }} />
    </BlockContainer>
  );
}

HtmlBlock.defaultProps = {
  content: undefined,
  containerType: 'segment-light',
  title: undefined,
};

HtmlBlock.propTypes = {
  _id: PropTypes.string.isRequired,
  content: PropTypes.string,
  containerType: containerTypePropTypes,
  title: PropTypes.string,
};

export default HtmlBlock;
