/* eslint-disable operator-linebreak */
/* eslint-disable react/no-danger */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Segment, Header, Grid, Container, Dimmer, Loader } from 'semantic-ui-react';
import { useRouteMatch } from 'react-router-dom';
import cx from 'classnames';
import { CmsBlocks } from '../cms/CmsScreen';
import { useScreenConfig } from '../config/screens.context';
import './Appointments.scss';
import { StepConfirmContent, StepConfirmSidebar } from './steps/Confirm';
import { StepPrepareContent, StepPrepareSidebar } from './steps/Prepare';
import { StepSelectSlotContent, StepSelectSlotSidebar } from './steps/SelectSlot';
import { StepContext } from './steps/StepContext';
import { cancelAppointment } from '../sponsors/blocks/BookAppointmentBlock/utils';
import { useTracking } from '../Context';
import { bookAppointment } from './utils';
import GridContainer from '../components/GridContainer';
import UserColoredCalendarBlock from '../agenda/blocks/UserColoredCalendarBlock';
import { useCurrentAppointment, useDaysWithSlots, useFreeTimeSlots } from './appointments.hooks';
import { bem } from '../core/design/bem';
import { CmsPageLayout } from '../cms/CmsPage';
import { withScreenClosed } from '../core/screens/screens.hoc';

const css = bem('appointment');

export const steps = {
  'select-slot': {
    Sidebar: StepSelectSlotSidebar,
    Content: StepSelectSlotContent,
  },
  confirm: {
    Sidebar: StepConfirmSidebar,
    Content: StepConfirmContent,
  },
  prepare: {
    Sidebar: StepPrepareSidebar,
    Content: StepPrepareContent,
  },
};

const Main = (props) => {
  const { pageId } = props;

  const match = useRouteMatch();
  const { _id: matchedGroupId } = match?.params || {};

  const { main = {}, days = [], appointmentUserRef = {} } = useScreenConfig(pageId);
  const { collection, _id: groupId = matchedGroupId } = appointmentUserRef;
  const { header, details } = main;
  const [step, setStep] = useState('select-slot');
  const [confirmData, setConfirmData] = useState({});
  const [selectedSlot, setSelectedSlot] = useState(null);
  const { trackEvent } = useTracking();
  const currentAppointment = useCurrentAppointment(collection, groupId);

  // userId remplacé par groupId car platfom front s'attend a un groupID
  const timeSlots = useFreeTimeSlots(collection, groupId);
  const { freeTimeSlots, loading } = timeSlots;
  const daysWithSlots = useDaysWithSlots(days, freeTimeSlots);

  useEffect(() => {
    setStep(currentAppointment ? 'prepare' : 'select-slot');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAppointment?._id]);

  function handleStep1Select(slot) {
    setSelectedSlot(slot);
    setStep('confirm');
  }

  async function handleConfirm(data) {
    const { startDate, endDate } = selectedSlot;
    const appointmentToBook = {
      startDate,
      endDate,
      groupId,
      message: data.message,
    };

    await bookAppointment(appointmentUserRef, appointmentToBook, {
      setStep,
      previousAppointment: currentAppointment,
    });

    setConfirmData(data);
  }

  async function handleCancel() {
    if (!currentAppointment) return;
    cancelAppointment(currentAppointment, appointmentUserRef, { trackEvent });
  }

  function handleUpdate() {
    setStep('select-slot');
  }

  const { Sidebar, Content } = steps[step] || {};

  return (
    <Segment>
      {header && <Header as="h1">{header}</Header>}
      {details && <div dangerouslySetInnerHTML={{ __html: details }} />}
      <StepContext.Provider
        value={{
          days: daysWithSlots,
          onSelect: handleStep1Select,
          onConfirm: handleConfirm,
          onCancel: handleCancel,
          onUpdate: handleUpdate,
          appointmentUserRef,
          setStep,
          freeTimeSlots,
          selectedSlot,
          confirmData,
          currentAppointment,
        }}
      >
        {loading ? (
          <Dimmer active inverted>
            <Loader inverted content="Loading" />
          </Dimmer>
        ) : (
          <Grid
            stackable
            style={{ marginTop: '1em', marginBottom: '1em' }}
            className={css({ hasAppointment: !!currentAppointment, step }).toString()}
          >
            <Grid.Column width={4}>{Sidebar && <Sidebar />}</Grid.Column>
            <Grid.Column width={12}>{Content && <Content />}</Grid.Column>
          </Grid>
        )}
      </StepContext.Provider>
    </Segment>
  );
};

Main.defaultProps = {
  pageId: 'appointments',
};

Main.propTypes = {
  pageId: PropTypes.string,
};

const sidebarComponents = {
  agenda: UserColoredCalendarBlock,
};

const Appointments = (props) => {
  const { pageId, metadata, design, pageHeader, pageFooter } = props;
  const { header, footer, sidebar } = useScreenConfig(pageId);
  return (
    <CmsPageLayout
      className={cx('page', 'page--appointments', pageId)}
      header={header}
      metadata={metadata}
      designOverride={design}
      pageHeader={pageHeader}
      pageFooter={pageFooter}
    >
      <Container>
        <div className="appointments--screen" style={{ marginTop: 14 }}>
          <GridContainer sidebar={sidebar} sidebarComponents={sidebarComponents}>
            {header && <CmsBlocks blocks={header.blocks} />}
            <Main pageId={pageId} />
            {footer && <CmsBlocks blocks={footer.blocks} />}
          </GridContainer>
        </div>
      </Container>
    </CmsPageLayout>
  );
};

Appointments.defaultProps = {
  design: {},
  metadata: undefined,
  pageFooter: undefined,
  pageId: 'appointments',
  pageHeader: undefined,
};

Appointments.propTypes = {
  design: PropTypes.object,
  metadata: PropTypes.object,
  pageFooter: PropTypes.object,
  pageId: PropTypes.string,
  pageHeader: PropTypes.object,
};

export default withScreenClosed(Appointments, { className: 'page--appointments' });
