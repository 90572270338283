import cx from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Embed, Modal } from 'semantic-ui-react';
import { useTracking } from '../../../Context';
import { eventTags } from '../../../core/trackers/events';
import OptLink from '../../../OptLink';
import { useUserTimezoneInfo } from '../../../utils/hooks';
import AddToCalendar from '../../AddToCalendar';
import Blocks from '../../Blocks';
import ImageIcon from '../../ImageIcon';
import './ActionsBlock.scss';

const IframeModal = ({ url, onClose }) => (
  <Modal className="modal--iframe" closeIcon onClose={onClose} open size="large">
    <Modal.Content>
      <Embed active url={url} iframe={{ allow: 'fullscreen;camera;microphone;display-capture' }} />
      {/* <iframe title="modal" src={url} allow="fullscreen;camera;microphone;display-capture" /> */}
    </Modal.Content>
  </Modal>
);

IframeModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
};

const AddToCalendarAction = ({ event }) => {
  const { description, descriptionHtml, title, link, location, timezones } = event;
  const { startDate, endDate, timezone } = useUserTimezoneInfo(timezones, event);
  const { trackEvent } = useTracking();

  const formattedEvent = {
    description,
    descriptionHtml,
    startDate: moment(startDate).toISOString(), // needed for ics
    endDate: moment(endDate).toISOString(),
    timezone,
    title,
    location,
    url: link,
  };
  const handleAdd = ({ calendarType }) => {
    trackEvent(eventTags.BUTTON_CLICK, {
      item: event,
      calendarType: calendarType?.toLowerCase(),
    });
  };
  return <AddToCalendar event={formattedEvent} onAdd={handleAdd} />;
};

export const Action = (block) => {
  const { type, color, variant, text, icon, url, style, className } = block;
  const [showModal, setShowModal] = useState(false);
  const { trackEvent } = useTracking();

  function handleClick(e) {
    if (type === 'iframe-modal') {
      e.preventDefault();
      e.stopPropagation();

      // Open
      setShowModal(true);
    }
    trackEvent(eventTags.CTA_GO_TO, block);
  }

  if (type === 'add-to-calendar' || variant === 'add-to-calendar') {
    return <AddToCalendarAction {...block} trackEvent={trackEvent} />;
  }

  return (
    <>
      <Button
        primary={color === 'primary'}
        className={cx('action', className, variant === 'link' ? 'button--link' : '')}
        style={variant === 'link' ? { color, ...style } : style}
        as={OptLink}
        to={url}
        onClick={handleClick}
      >
        <div
          className="action-container"
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          <ImageIcon icon={icon} {...icon} style={{ marginRight: 8, ...style?.icon }} />
          {text}
        </div>
      </Button>
      {showModal && <IframeModal url={url} onClose={() => setShowModal(false)} />}
    </>
  );
};

Action.defaultProps = {
  color: undefined,
  icon: undefined,
  style: {},
  type: undefined,
  variant: 'button',
};

Action.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.object,
  style: PropTypes.object,
  text: PropTypes.string.isRequired,
  type: PropTypes.string,
  url: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['button', 'primary', 'link']),
};

const blockComponents = { action: Action, 'add-to-calendar': AddToCalendarAction };

function ActionsBlock({ _id, blocks }) {
  if (!blocks || blocks.length === 0) return null;
  return (
    <div className={cx('block--actions', _id)}>
      <Blocks blocks={blocks} blockComponents={blockComponents} />
    </div>
  );
}

ActionsBlock.propTypes = {
  _id: PropTypes.string.isRequired,
  blocks: PropTypes.arrayOf(PropTypes.shape({ type: PropTypes.string })).isRequired,
};

export default ActionsBlock;
