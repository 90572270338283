import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import values from 'lodash/values';
import orderBy from 'lodash/orderBy';
import { List, Image, Icon, Label } from 'semantic-ui-react';
import { avatarWithFallbackWhenMissing } from '../../utils/avatarUtils';
import { useScreenConfig } from '../../config/screens.context';
import { getUserUnreadCount } from '../store/networking.helpers';

const ChatList = ({ chats, me }) => {
  const { displayCustomFields } = useScreenConfig('networking');
  const validFields = (displayCustomFields || []).filter((field) => field.type !== 'tag');
  const contactList = orderBy(values(chats), 'createdAt', 'desc');
  const imageSize = 50;
  return (
    <List divided verticalAlign="middle" relaxed selection className="item--table">
      {contactList.map((chat) => {
        const profile = (me === chat.inviter ? chat.invitedProfile : chat.inviterProfile) || {};
        const name = `${profile.firstName} ${profile.lastName}`;
        const unreadCount = getUserUnreadCount(chat);
        return (
          <List.Item
            key={`chat-preview-${chat.id}`}
            className="item--table"
            as={NavLink}
            to={`/networking/chats/${chat.hash}`}
            aria-label={name}
            tabIndex={0}
          >
            <div className="image-container" style={{ width: imageSize }}>
              <Image
                avatar
                style={{ width: imageSize, height: imageSize }}
                src={avatarWithFallbackWhenMissing(profile.thumbnail, imageSize)}
                alt={`${name}'s avatar`}
              />
            </div>
            <List.Content>
              {!!unreadCount && (
                <Label color="blue" style={{ float: 'right', marginTop: -3, marginBottom: -3 }}>
                  {unreadCount}
                </Label>
              )}
              <List.Header>{name}</List.Header>
              <List.Description>
                {validFields
                  .filter((field) => profile[field.field])
                  .map((field) => (
                    <div key={field.field}>
                      {field.icon && <Icon name={field.icon} />}&nbsp;{profile[field.field]}
                    </div>
                  ))}
              </List.Description>
            </List.Content>
          </List.Item>
        );
      })}
    </List>
  );
};

ChatList.propTypes = {
  chats: PropTypes.object.isRequired,
  me: PropTypes.string.isRequired,
};
export default ChatList;
