import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button, Header, Segment } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useTracking } from '../../Context';
import { eventTags } from '../../core/trackers/events';
import store from '../../shared/Store';
import { replaceValues, replaceUriValues } from '../../utils/stringUtils';

const translationPrefix = `blocks.cta`;

const CTABlock = ({ item, field, strings, eventTag }) => {
  const { trackEvent } = useTracking();
  const { t } = useTranslation();
  const user = useSelector((state) => state.user.user);
  const injectedValues = useMemo(() => ({ item, user }), [item, user]);

  const { url, header, description, button } = useMemo(() => {
    return {
      url: replaceUriValues(item[field], injectedValues),
      header: replaceValues(strings.header ?? t(`${translationPrefix}.header`), injectedValues),
      description: replaceValues(
        strings.description ?? t(`${translationPrefix}.description`),
        injectedValues,
      ),
      button: replaceValues(strings.button ?? t(`${translationPrefix}.button`), injectedValues),
    };
  }, [item, field, strings, injectedValues]);

  if (!url) return null;

  function trackGoToPage() {
    trackEvent(eventTag, {
      userId: store.userId,
      item,
      url,
    });
  }

  return (
    <Segment>
      <Header as="h3">{header}</Header>
      {description && <p>{description}</p>}
      <Button
        primary
        fluid
        as="a"
        href={url}
        target="_blank"
        rel="noreferrer noopener"
        onClick={trackGoToPage}
      >
        {button}
      </Button>
    </Segment>
  );
};

CTABlock.defaultProps = {
  eventTag: eventTags.ITEM_CTA_GO_TO,
};

CTABlock.propTypes = {
  item: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired,
  eventTag: PropTypes.string,
  strings: PropTypes.shape({
    header: PropTypes.string.isRequired,
    description: PropTypes.string,
    button: PropTypes.string.isRequired,
  }).isRequired,
};

export default CTABlock;
