/* eslint-disable react/no-array-index-key */
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Container, Grid } from 'semantic-ui-react';

import './Profile.scss';
import { useIsAnonymous } from './hooks';

import { CmsPageLayout } from '../cms/CmsPage';

import Blocks from '../components/Blocks';
import HtmlBlock from '../components/cms/HtmlBlock';

import { useScreenConfig } from '../config/screens.context';

import { bem } from '../core/design/bem';

import Rewards from './blocks/Rewards';
import UserProfile from './blocks/UserProfile/UserProfile';
import UserSchedule from './blocks/UserSchedule';

import UserProfileNetworkingBlock from './components/UserProfileNetworkingBlock';
import { CmsBlocks } from '../cms/CmsScreen';

const defaultBlocks = [{ type: 'userProfile' }, { type: 'agenda' } /* , {type: "rewards"} */];

const blockComponents = {
  agenda: UserSchedule,
  userProfile: UserProfile,
  rewards: Rewards, // <Rewards columnByRow missionList rewards user={Store.user} />
  html: HtmlBlock,
  cms: CmsBlocks,
  networking: UserProfileNetworkingBlock,
};

const Profile = ({ user }) => {
  const { blocks = defaultBlocks } = useScreenConfig('profile');

  return (
    <Grid className="profile--block">
      <Blocks
        blocks={blocks}
        blockComponents={blockComponents}
        sharedProps={{
          user,
        }}
      />
    </Grid>
  );
};

Profile.defaultProps = {
  user: {
    firstName: '',
    lastName: '',
    email: '',
    thumbnail: null,
    networking: false,
  },
};

Profile.propTypes = {
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    networking: PropTypes.bool,
    rewards: PropTypes.object,
    thumbnail: PropTypes.object,
  }),
};

const ConnectedProfile = connect((state) => ({
  ...state.user,
}))(Profile);

const css = bem('page');
export const ProfileScreen = ({
  header,
  metadata,
  design,
  pageHeader,
  pageFooter,
  pageId,
  ...rest
}) => {
  const { redirectTo = '/' } = rest;
  const isAnonymous = useIsAnonymous();
  if (isAnonymous) {
    return <Redirect to={redirectTo} />;
  }
  return (
    <CmsPageLayout
      className={css({
        type: 'profile',
        id: pageId,
      }).toString()}
      header={header}
      metadata={metadata}
      designOverride={design}
      pageHeader={pageHeader}
      pageFooter={pageFooter}
    >
      <Container>
        <ConnectedProfile />
      </Container>
    </CmsPageLayout>
  );
};

ProfileScreen.defaultProps = {
  pageId: 'profile',
  pageFooter: undefined,
  pageHeader: undefined,
  design: {},
  header: {},
};

ProfileScreen.propTypes = {
  pageFooter: PropTypes.object,
  pageHeader: PropTypes.object,
  design: PropTypes.object,
  header: PropTypes.object,
  pageId: PropTypes.string,
};

export default ConnectedProfile;
