import { matchPath, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { tabsFromReduxState } from './useNetworkingTabs.helpers';
import { useInviteMode } from '../InviteModes.hooks';

function useNetworkingTabs() {
  const inviteMode = useInviteMode();
  const location = useLocation();
  const tabs = useSelector(tabsFromReduxState(inviteMode));
  const activePane = tabs.findIndex(({ menuItem }) => {
    return matchPath(location.pathname, {
      path: menuItem.to,
      exact: menuItem.exact,
    });
  });
  return { tabs, activePane };
}

export default useNetworkingTabs;
