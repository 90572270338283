import React from 'react';
import { Button, Grid, Header } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { useScreenConfig } from '../../config/screens.context';
import { getString } from '../../utils';
import { localeFormat } from '../../utils/date';
import { SlotDay } from '../components/Slots/SlotDay';
import { StepContainer } from '../components/Step';
import { useStepContext } from './StepContext';
import { userAgenda } from '../../agenda/store/agenda.selectors';
import { checkUserAvailability } from '../../utils/agendaUtils';

const translationPrefix = 'appointments.steps';
const t = (key, data) => getString(`${translationPrefix}.${key}`, data);
const defaultFreeTimeSlots = [];

export const StepSelectSlotSidebar = () => {
  const { onSelect, freeTimeSlots = defaultFreeTimeSlots } = useStepContext();
  const agenda = useSelector(userAgenda);

  const nextSlot = freeTimeSlots.find((slot) => {
    const otherEvents = agenda.filter((evt) => evt.slotId !== slot._id);
    return checkUserAvailability(otherEvents, slot);
  });

  return (
    <div>
      <StepContainer label="1" active>
        <Header as="h4">{t('select-slot.sidebar-label')}</Header>
        {nextSlot && (
          <div className="Step__nextFreeSlot">
            <p>{t('select-slot.next-free')}</p>
            <Button primary size="tiny" compact onClick={() => onSelect(nextSlot)}>
              {localeFormat(nextSlot.startDate, 'PPPp')}
            </Button>
          </div>
        )}
        {!nextSlot && <p>{t('select-slot.no-slot-left')}</p>}
      </StepContainer>
      <StepContainer label="2" top>
        {t('confirm.sidebar-label')}
      </StepContainer>
      <StepContainer label="3" top>
        {t('prepare.sidebar-label')}
      </StepContainer>
    </div>
  );
};
export const StepSelectSlotContent = () => {
  const { components = {}, allowOverlappingSessions } = useScreenConfig('appointments');
  const { days, onSelect } = useStepContext();
  return (
    <Grid
      className="step-select-slot-grid"
      columns={days.length}
      centered
      style={{ marginTop: 0, flexWrap: 'wrap' }}
      stackable
    >
      {days.map((day, index) => {
        const dayColumnStyle = days.length < 3 ? { minWidth: 320, flexGrow: 1 } : { flexGrow: 1 };
        return (
          <Grid.Column key={String(index)} style={dayColumnStyle}>
            <SlotDay
              {...day}
              onSelect={onSelect}
              {...components.slotDay}
              allowOverlappingSessions={allowOverlappingSessions}
            />
          </Grid.Column>
        );
      })}
    </Grid>
  );
};
