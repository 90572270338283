import { useMount } from 'ahooks';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import moment, { ISO_8601 } from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Grid, Segment } from 'semantic-ui-react';
import AgendaDay from '../../Layouts/VideoBackground/pages/VideoBackgroundAgendaPage/components/AgendaDay';
import store from '../../shared/Store';
import { throttleDispatchFetch } from '../../utils';
import { fetchWorkshops } from '../../workshops/store/workshops.actions';
import './AgendaWeek.scss';

function extractDay(event) {
  const { startDate } = event;
  if (!startDate) return undefined;
  return moment(startDate, ISO_8601).format('YYYY-MM-DD');
}

const throttledFetch = throttleDispatchFetch(() => store.reduxStore.dispatch(fetchWorkshops()));

const AgendaWeek = ({ events, dayMode }) => {
  // const { startDate, endDate } = props;
  // const events = useSelector(userAgenda);
  const workshopSessions = useSelector((state) => state.workshopSessions.workshopSessions);
  const byDate = groupBy(events, extractDay);
  const startDates = orderBy([...events, ...workshopSessions], 'startDate', 'asc').sort();

  const dates = Object.keys(groupBy(startDates, extractDay));

  useMount(() => {
    // Force refresh workshops
    throttledFetch();
  });
  if (dates.length === 0) return null; // Still loading
  return (
    <Segment className="AgendaWeek">
      <Grid columns={dates.length} stackable divided>
        {dates.map((date, index) => (
          <Grid.Column key={date}>
            <AgendaDay index={index} day={date} events={byDate[date] || []} mode={dayMode} />
          </Grid.Column>
        ))}
      </Grid>
    </Segment>
  );
};

AgendaWeek.defaultProps = {
  dayMode: 'items',
  events: [],
};

AgendaWeek.propTypes = {
  dayMode: PropTypes.oneOf(['items', 'variable-height']),
  events: PropTypes.array,
};

export default AgendaWeek;
