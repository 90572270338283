/* eslint-disable react/prop-types */
import cx from 'classnames';
import pick from 'lodash/pick';
import React from 'react';
import { Container } from 'semantic-ui-react';
import { CmsPageLayout } from '../../cms/CmsPage';
import { CmsBlocks } from '../../cms/CmsScreen';

export function pickPageLayoutProps(props) {
  return pick(props, [
    'pageId',
    'header',
    'metadata',
    'designOverride',
    'pageHeader',
    'pageFooter',
  ]);
}

const ClosedScreen = ({ pageLayoutConfig, className, blocks }) => {
  const { pageId, ...rest } = pageLayoutConfig;
  return (
    <CmsPageLayout className={cx('page', className, pageId)} {...rest}>
      <Container>
        <CmsBlocks blocks={blocks} />
      </Container>
    </CmsPageLayout>
  );
};

export function withScreenClosed(Screen, options) {
  const { className } = options || {};
  return (props) => {
    const { screenClosed, ...rest } = props;
    if (screenClosed?.isClosed) {
      return (
        <ClosedScreen
          pageLayoutConfig={pickPageLayoutProps(props)}
          className={className}
          blocks={screenClosed.blocks}
        />
      );
    }
    return <Screen {...rest} />;
  };
}
