import React from 'react';
import { useTranslation } from 'react-i18next';
import { useComponentOptions } from '../../config/design.context';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const IOSPWAPrompt = require('react-ios-pwa-prompt').default;

const PWAPrompt = (): JSX.Element | null => {
  const { t } = useTranslation();
  const { enabled, ...options } = useComponentOptions('PWAPrompt');

  if (!enabled) return null;

  return (
    <IOSPWAPrompt
      promptOnVisit={1}
      timesToShow={3}
      permanentlyHideOnDismiss={false}
      {...t('components.PWAPrompt', { returnObjects: true })}
      {...options}
    />
  );
};

export default React.memo(PWAPrompt);
