import get from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';
import BlockContainer from '../../components/BlockContainer';
import HtmlBlock from '../../components/cms/HtmlBlock';

const ItemHtmlBlock = ({ item, field, header, ...props }) => {
  const content = get(item, field);
  if (!content) return null;
  return <HtmlBlock title={header} header={header} {...props} content={content} />;
};

ItemHtmlBlock.defaultProps = {
  containerType: 'segment',
  field: 'image',
  header: undefined,
};

ItemHtmlBlock.propTypes = {
  containerType: BlockContainer.propTypes.type,
  field: PropTypes.string,
  header: PropTypes.string,
  item: PropTypes.object.isRequired,
};

export default ItemHtmlBlock;
