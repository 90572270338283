/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import BlockContainer from '../BlockContainer';
import useTranslations from '../../hooks/useTranslations';
import { replaceValues } from '../../utils/stringUtils';
import CTATilesBlock from './CTATilesBlock';
import { bem } from '../../core/design/bem';

const pageBlock = bem('cta');
const translationPrefix = 'blocks.ctaTiles';

const ItemCTATilesBlock = ({ container, item, field, ...props }) => {
  const { t } = useTranslations(translationPrefix);
  const blocks = get(item, field);
  if (!blocks || blocks.length === 0) return null;

  return (
    <BlockContainer
      {...container}
      className={pageBlock({ variant: props?.variant }).toString()}
      header={replaceValues(container?.header || t('cta'), { ...item })}
    >
      <CTATilesBlock blocks={get(item, field)} {...props} />
    </BlockContainer>
  );
};

ItemCTATilesBlock.propTypes = {};

export default ItemCTATilesBlock;
