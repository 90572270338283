/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'semantic-ui-react';
import SquareImage from '../../../SquareImage';
import { EntityTags } from '../../../../workshops/components/WorkshopTable/WorkshopModal';
import { bem } from '../../../../core/design/bem';
import './SquareSpeakerHeader.scss';
import Images from '../../../../utils/Images';

const css = bem('SquareSpeakerHeader');

const SquareSpeakerHeader = ({ fluid, speaker, ...rest }) => {
  const { company, description, firstName, lastName, logo, role, thumbnail } = speaker;
  const { tagFields = [] } = rest;
  const hasImage = Images.exists(logo);

  return (
    <Card className={css()} fluid={fluid}>
      {thumbnail && (
        <div>
          <SquareImage
            as="img"
            src={thumbnail}
            alt={`${firstName} ${lastName}`}
            className={css('avatar').toString()}
            imageSize={200}
          />
        </div>
      )}

      <Card.Content className={css('content').toString()}>
        <div className={css('header').toString()}>
          <div className={css('header', { left: true }).toString()}>
            <SquareImage
              as="img"
              src={logo}
              alt={`${firstName} ${lastName}`}
              className={css('logo').toString()}
              imageSize={60}
              func="fit"
            />
          </div>
          <div
            className={css('header', { right: true }).state({ logoEmpty: !hasImage }).toString()}
          >
            <Card.Header className={css('firstNameLastName').toString()}>
              {firstName} {lastName}
            </Card.Header>
            <Card.Description className={css('companyRole').toString()}>
              {company} - {role}
            </Card.Description>
          </div>
        </div>

        {description && (
          <Card.Description
            className={css('description').toString()}
            style={{ textAlign: 'justify', marginTop: 20 }}
          >
            <div dangerouslySetInnerHTML={{ __html: description }} />
          </Card.Description>
        )}
        <EntityTags tagFields={tagFields} entity={speaker} type="speaker" />
      </Card.Content>
    </Card>
  );
};

SquareSpeakerHeader.defaultProps = {
  fluid: undefined,
  template: undefined,
};
SquareSpeakerHeader.propTypes = {
  fluid: PropTypes.bool,
  template: PropTypes.string,
  speaker: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    slug: PropTypes.string,
    shortDescription: PropTypes.string,
    description: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    company: PropTypes.string,
    role: PropTypes.string,
    logo: PropTypes.object,
    thumbnail: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    url: PropTypes.string,
  }).isRequired,
};

export default SquareSpeakerHeader;
