import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'semantic-ui-react';
import SpeakerCard from '../../components/speakers/SpeakerCard';

const defaultExtraFields = [{ dataKey: 'email', lineType: 'extra' }];

const UserGrid = ({
  className,
  items,
  imageSize,
  itemsPerRow,
  width,
  extraFields,
  variant,
  ...rest
}) => {
  return (
    <Card.Group
      className={className}
      itemsPerRow={width ? undefined : itemsPerRow}
      style={{ marginTop: 0, marginBottom: 0 }}
    >
      {items.map((speaker) => (
        <SpeakerCard
          {...rest}
          key={speaker._id}
          extraFields={extraFields}
          speaker={speaker}
          width={width}
          imageSize={imageSize}
          showLink={false}
          variant={variant}
        />
      ))}
    </Card.Group>
  );
};
UserGrid.defaultProps = {
  className: undefined,
  extraFields: defaultExtraFields,
  imageSize: 110,
  itemsPerRow: 3,
  variant: 'light',
  width: undefined,
};
UserGrid.propTypes = {
  className: PropTypes.string,
  extraFields: PropTypes.arrayOf(PropTypes.shape({ dataKey: PropTypes.string })),
  imageSize: PropTypes.number,
  items: PropTypes.array.isRequired,
  itemsPerRow: PropTypes.number,
  variant: PropTypes.string,
  width: PropTypes.number,
};

export default UserGrid;
