import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import orderBy from 'lodash/orderBy';
import { Dropdown, Menu } from 'semantic-ui-react';

export function computeOptions(options) {
  return orderBy(
    options.map(({ text, label, key, value }) => ({
      key,
      text: text || label,
      value: key || value,
    })),
    (o) => o.text?.toLowerCase(),
  );
}

function SelectFilter(props) {
  const { filter, value, onSelect } = props;
  const { title, placeholder, values = [] } = filter;
  const dropdownOptions = useMemo(() => computeOptions(values), [values]);
  return (
    <Menu.Item>
      <Menu.Header>{title}</Menu.Header>
      <Dropdown
        className="select--dropdown"
        fluid
        selection
        placeholder={placeholder}
        labeled
        search
        clearable
        options={dropdownOptions}
        value={value}
        onChange={(e, option) => onSelect(filter, option?.value || undefined)}
      />
    </Menu.Item>
  );
}

SelectFilter.defaultProps = {
  value: undefined,
};

SelectFilter.propTypes = {
  filter: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  value: PropTypes.string,
};

export default SelectFilter;
