/* eslint-disable import/no-cycle */
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, Icon } from 'semantic-ui-react';
import { userAgenda } from '../../../agenda/store/agenda.selectors';
import UserRegistrationModal from '../../../authentication/components/UserRegistrationModal/UserRegistrationModal';
import { workshopProptypes } from '../../../propTypes';
import { checkUserAvailability } from '../../../utils/agendaUtils';
import Images from '../../../utils/Images';
import WorkshopActions from '../../../workshops/components/WorkshopActions';
import { EntityTags } from '../../../workshops/components/WorkshopTable/WorkshopModal';
import { getClassName } from '../../../workshops/utils';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import EntityLink from '../../EntityLink';
import SpeakerAvatars from '../../speakers/SpeakerAvatars';
import QuotaField from '../QuotaField';
import WorkshopDate from '../WorkshopDate';
import WorkshopLogos from '../WorkshopLogos';
import './VodCard.scss';

const VodCard = (props) => {
  const { workshop, showSpeakers, speakersConfig, actions, tagFields, ...rest } = props;
  const {
    _id,
    endDate,
    image,
    quota,
    shortDescription,
    speakers,
    startDate,
    title,
    usersCount,
    workshopDuration: duration,
    workshopStatus,
    logos,
    timezone,
  } = workshop;
  const { isHighlighted = true, actionConfig = {}, logosProps, enableClick = true, tagSize } =
    rest || {};
  const { useOverlay = false } = actionConfig;
  const [isOpen, setIsOpen] = useState(false);
  const hasImage = image && image.uri;
  const agenda = useSelector(userAgenda);
  const [showActions, setShowActions] = useState(false);
  const hasOverlappingEvent = useMemo(() => {
    const otherEvents = agenda.filter((evt) => evt._id !== _id);
    return !checkUserAvailability(otherEvents, workshop);
  }, [agenda, workshop, _id]);

  return (
    <>
      <div
        className={cx(
          'workshop--vod',
          getClassName(workshop),
          `session--${workshopStatus}`,
          hasOverlappingEvent && `session--has-overlapping-event`,
          { hidden: !isHighlighted },
        )}
      >
        <Card as={enableClick ? EntityLink : undefined} entity={{ ...workshop, kind: 'workshops' }}>
          {hasImage && (
            <div className="card__image">
              {useOverlay && (
                <div className={cx('overlay', { hasActions: showActions })}>
                  {actions?.length > 0 && (
                    <>
                      <Icon
                        className="ellipsis"
                        name="ellipsis vertical"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setShowActions(!showActions);
                        }}
                      />
                      {showActions && (
                        <WorkshopActions
                          actions={actions}
                          workshop={workshop}
                          onClick={setIsOpen}
                        />
                      )}
                    </>
                  )}
                </div>
              )}
              <div
                className="image-with-shadow"
                style={{
                  backgroundImage: `url(${Images.maxWidth(image, 800)})`,
                }}
              />
              {duration && <div className="duration">{duration}</div>}
            </div>
          )}
          <Card.Content className="borderless">
            <Card.Description className="date" style={{ marginTop: 8, marginBottom: 8 }}>
              <WorkshopDate startDate={startDate} endDate={endDate} timezone={timezone} />
            </Card.Description>
            <WorkshopLogos logos={logos} {...logosProps} />
            <QuotaField quota={quota} usersCount={usersCount} />
            <Card.Header className="title" style={{ fontSize: '1.2em', fontWeight: 'normal' }}>
              {title}
            </Card.Header>
            <Card.Description className="shortDescription">{shortDescription}</Card.Description>
            <EntityTags tagFields={tagFields} entity={workshop} type="workshop" size={tagSize} />
            {showSpeakers && <SpeakerAvatars speakerIds={speakers} {...speakersConfig} />}
            {!useOverlay && actions?.length > 0 && (
              <WorkshopActions actions={actions} workshop={workshop} onClick={setIsOpen} />
            )}
          </Card.Content>
        </Card>
      </div>
      {isOpen && (
        <UserRegistrationModal
          onClose={() => {
            setIsOpen(false);
          }}
        />
      )}
    </>
  );
};

VodCard.defaultProps = {
  actions: undefined,
  showSpeakers: false,
  speakersConfig: {},
  tagFields: [],
};

VodCard.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.object),
  showSpeakers: PropTypes.bool,
  speakersConfig: PropTypes.object,
  tagFields: PropTypes.arrayOf(PropTypes.string),
  workshop: PropTypes.shape(workshopProptypes).isRequired,
};

export default VodCard;
