import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Message, Segment } from 'semantic-ui-react';
import { getString } from '../../utils';
import { workshopPropType } from '../utils';
import { WorkshopAddToCalendarBlock } from './WorkshopAddToCalendarBlock';
import { DateTimeDisplay } from '../../components/DateTimeDisplay';
import QuotaField from '../../components/workshops/QuotaField';
import { useWorkshopRegistration } from '../utils/workshopRegistrationUtils';

const translationPrefix = 'workshops.workshop';

const WorkshopRegistrationBlock = ({ item: workshop, registration }) => {
  const {
    isWorkshopFull,
    isWorkshopEnded,
    loading,
    registerWorkshop,
    unregisterWorkshop,
  } = useWorkshopRegistration(workshop);

  const { startDate, endDate, mandatory, quota, usersCount } = workshop;

  if (isWorkshopEnded) {
    return (
      <Segment className="workshop--registrationBlock">
        <Message>
          <Message.Header>{getString(`${translationPrefix}.workshop-ended`)}</Message.Header>
        </Message>
      </Segment>
    );
  }

  if (registration || mandatory) {
    // Already registered...
    return (
      <Segment className="workshop--registrationBlock">
        <Message>
          <Message.Header>
            <DateTimeDisplay startDate={startDate} endDate={endDate} />
          </Message.Header>
          {!mandatory && <p>{getString(`${translationPrefix}.already-registered`)}</p>}
          {mandatory && (
            <p style={{ textAlign: 'justify', fontWeight: 'bold', color: 'red' }}>
              {getString(`${translationPrefix}.mandatory-registered`)}
            </p>
          )}
          <WorkshopAddToCalendarBlock workshop={workshop} />
          {!mandatory && (
            <Button
              color="red"
              onClick={unregisterWorkshop}
              loading={loading}
              disabled={loading}
              size="tiny"
              style={{ width: '100%' }}
            >
              {getString(`${translationPrefix}.unregister`)}
            </Button>
          )}
        </Message>
      </Segment>
    );
  }

  return (
    <Segment className="workshop--registrationBlock">
      {workshop.startDate ? (
        <Message>
          <Message.Header>
            <DateTimeDisplay startDate={startDate} endDate={endDate} />
          </Message.Header>
          {isWorkshopFull && (
            <p className="full-workshop-label" style={{ color: '#b44b4b', fontWeight: 'bold' }}>
              {getString(`${translationPrefix}.workshop-full`)}
            </p>
          )}
          {!isWorkshopFull && (
            <>
              <QuotaField quota={quota} usersCount={usersCount} />
              <p className="interested-by-workshop-label">
                {getString(`${translationPrefix}.interested-by-this-workshop`)}
              </p>

              <Button
                className="registration-button"
                primary
                onClick={registerWorkshop}
                loading={loading}
                disabled={loading}
                style={{ width: '100%' }}
              >
                {getString(`${translationPrefix}.register`)}
              </Button>
            </>
          )}
        </Message>
      ) : (
        <Message>
          <p>{getString(`${translationPrefix}.registrations-not-yet-open`)}</p>
        </Message>
      )}
    </Segment>
  );
};

WorkshopRegistrationBlock.defaultProps = {
  registration: undefined,
};

WorkshopRegistrationBlock.propTypes = {
  registration: PropTypes.object, // current registration, or undefined
  item: workshopPropType.isRequired,
};

export default connect((state, props) => ({
  registration: state.registrations.registrationsById[props.item._id],
}))(WorkshopRegistrationBlock);
