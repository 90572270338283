import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Table } from 'semantic-ui-react';

import get from 'lodash/get';
import noop from 'lodash/noop';
import values from 'lodash/values';
import some from 'lodash/some';

import WorkshopActions from '../WorkshopActions';

import { workshopProptypes } from '../../../propTypes';
import { getWorkshopString } from '../../utils';

export function findOverlappingSession(session, otherSessions) {
  const { startDate, endDate } = session;
  return otherSessions.find(
    (os) => os._id !== session._id && startDate < os.endDate && endDate > os.startDate,
  );
}

function isSessionDisabled(session, sessionIds, registerOncePerSlot) {
  if (!registerOncePerSlot) return false;
  const { quota, usersCount, mandatory, workshopId } = session;

  if (mandatory) return false; // Always available....

  const sameSlot = sessionIds[session.startDate];
  if (sameSlot) {
    return sameSlot.workshopId !== workshopId; // Block other slots, keep this one
  }

  const isSessionFull = quota - usersCount === 0;
  if (isSessionFull) return true;

  const registeredSessions = values(sessionIds);
  if (findOverlappingSession(session, registeredSessions)) {
    return true; // Found overlapping session
  }

  // Find other slot with the same workshopId ?
  if (workshopId && some(registeredSessions, (s) => s.workshopId === workshopId)) {
    return true;
  }

  return false;
}

function renderField(field, session) {
  const { type, key } = field;
  const value = get(session, key);
  switch (type) {
    case 'quota': {
      if (!value) return null;
      const { usersCount } = session;
      return `${value - usersCount || 0}/${value}`;
    }
    default:
      return getWorkshopString(session, key) || value;
  }
}

function WorkshopLineFields(props) {
  const { session, fields, actions, onSelect, registerOncePerSlot, selectedSessions } = props;
  const isDisabled = isSessionDisabled(session, selectedSessions, registerOncePerSlot);

  return (
    <>
      {fields.map((field) => (
        <Table.Cell
          key={field.key}
          className={cx(field.key, { disabled: isDisabled })}
          style={field.style}
        >
          {renderField(field, session)}
        </Table.Cell>
      ))}
      {actions?.length > 0 && (
        <Table.Cell className={cx('actions', { disabled: isDisabled })}>
          <WorkshopActions actions={actions} workshop={session} onSelect={onSelect} />
        </Table.Cell>
      )}
    </>
  );
}
WorkshopLineFields.defaultProps = {
  actions: [],
  fields: [],
  onSelect: noop,
  selectedSessions: undefined,
  registerOncePerSlot: false,
};

WorkshopLineFields.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.object),
  fields: PropTypes.arrayOf(PropTypes.object),
  onSelect: PropTypes.func,
  registerOncePerSlot: PropTypes.bool,
  selectedSessions: PropTypes.object,
  session: PropTypes.shape(workshopProptypes).isRequired,
};

export default WorkshopLineFields;
