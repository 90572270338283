import PropTypes from 'prop-types';
import React from 'react';
import { Header, Icon, Image } from 'semantic-ui-react';
import BlockContainer from '../../components/BlockContainer';
import HtmlTemplate from '../../components/HtmlTemplate';
import WorkshopDate from '../../components/workshops/WorkshopDate';
import WorkshopLogos from '../../components/workshops/WorkshopLogos';
import { containerProptypes } from '../../propTypes';
import Images from '../../utils/Images';
import Tag from '../components/Tag';
import { useFieldLabel } from '../components/WorkshopsBlock/WorkshopsBlock';
import { EntityTags } from '../components/WorkshopTable/WorkshopModal';
import { getWorkshopString } from '../utils';

const WorkshopDetailsBlock = ({ item, container, ...rest }) => {
  const { tagFields = [], showDuration, showEndTime = true, logosProps } = rest;
  const {
    endDate,
    image,
    startDate,
    description,
    languages,
    title,
    technicityLevel,
    productCategories,
    shortDescription,
    logos,
  } = item;
  const hasImage = Images.exists(image);

  const labelProvider = useFieldLabel({ kind: 'workshops', ...item });

  return (
    <BlockContainer {...container} className="workshop-details-segment">
      <div className="workshop--details">
        {hasImage && (
          <div style={{ flexShrink: 0 }}>
            <Image className="workshop--details__image" src={Images.cdn(image)} />
          </div>
        )}
        <div className="workshop--details__content" style={{ paddingLeft: hasImage ? 16 : 0 }}>
          <p className="workshop--details__content--category">
            {getWorkshopString(item, 'category')}
          </p>
          <div className="date-title">
            <p style={{ marginBottom: 6 }} className="workshop--details__content--date">
              <WorkshopDate
                startDate={startDate}
                endDate={endDate}
                showEndTime={showEndTime}
                showDuration={showDuration}
              />
            </p>
            {languages && languages.length > 0 && (
              <div
                className="workshop--details__content--languages"
                style={{ marginTop: 0, marginBottom: 14 }}
              >
                <Icon name="flag" />
                {languages.map((v) => labelProvider('languages', v)).join(', ')}
              </div>
            )}
            <Header
              className="workshop--details__content--title"
              as="h3"
              style={{ marginTop: 2, marginBottom: 14 }}
            >
              {title}
            </Header>
          </div>
          <WorkshopLogos logos={logos} {...logosProps} />
          <EntityTags tagFields={tagFields} entity={item} type="workshop" />
          <div className="tags">
            {technicityLevel && (
              <Tag value={technicityLevel} translationPrefix="workshop.tag.technicityLevel" />
            )}
            {productCategories &&
              productCategories.length > 0 &&
              productCategories.map((pc) => (
                <Tag value={pc} translationPrefix="workshop.tag.productCategories" />
              ))}
          </div>
        </div>
      </div>
      {!description && shortDescription && (
        <p className="workshop--details__shortDescription">{shortDescription}</p>
      )}
      {description && (
        <HtmlTemplate
          className="workshop--details__description"
          style={{ fontSize: '1.2em', marginTop: 16 }}
          template={description}
        />
      )}
    </BlockContainer>
  );
};

WorkshopDetailsBlock.defaultProps = {
  container: {},
};

WorkshopDetailsBlock.propTypes = {
  container: containerProptypes,
  item: PropTypes.object.isRequired,
};

export default WorkshopDetailsBlock;
