import get from 'lodash/get';
import pick from 'lodash/pick';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Header, Icon, Label } from 'semantic-ui-react';

import './ApplyOpportunityBlock.scss';

import AppointmentsBlock from '../../appointments/AppointmentsBlock';
import { useCurrentAppointment, useFreeTimeSlots } from '../../appointments/appointments.hooks';
import { bem } from '../../core/design/bem';
import platformService from '../../core/services/platform.service';
import { uuid } from '../../core/trackers/utils';
import store from '../../shared/Store';
import { sweetAlert } from '../../utils/popupUtils';

const css = bem('apply-opportunity');

const translationPrefix = 'blocks.apply-opportunity';

function ApplyOpportunityBlock(props) {
  const { t } = useTranslation();
  const { item, appointment } = props;
  const { userRef = {}, bookingFields = [], showContactMeBlock = true } = appointment;
  const fields = bookingFields.map(({ key, itemField }) => ({
    [key]: get(item, itemField || key),
  }));
  const extraBookingData = Object.assign({}, ...fields);
  const { _id, collection, name } = userRef;
  const userId = get(item, _id) || _id;
  const userName = get(item, name) || name;
  const appointmentUserRef = { _id: userId || _id, collection, name: userName };
  const [isOpen, setIsOpen] = useState(false);
  const registration = useCurrentAppointment(collection, userId);
  const slotFilters = { opportunityId: item._id };
  const { freeTimeSlots } = useFreeTimeSlots(collection, userId, slotFilters);
  const hasSlots = freeTimeSlots?.length > 0;
  const [interestedCandidates, setInterstedCandidates] = useState([]);
  const userAlreadyRequestedToBeCalled = interestedCandidates.length > 0;

  async function fetchInterestedCandidates() {
    const candidates = await platformService.fetchUserCustomData('interestedCandidates');
    setInterstedCandidates(candidates);
  }

  const refresh = async () => {
    await fetchInterestedCandidates();
  };

  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleContactMe = async () => {
    const data = {
      _id: uuid(),
      groupId: userId,
      userId: store.userId,
      item: pick(item, ['_id', 'name']),
    };
    try {
      const res = await platformService.addData('interestedCandidates', data);
      if (res.success) {
        await refresh();
        sweetAlert({ icon: 'info', title: t(`${translationPrefix}.successful-demand`) });
      }
    } catch (error) {
      sweetAlert({
        icon: 'error',
        title: t(`${translationPrefix}.error-demand`),
      });
    }
  };

  return (
    <div className={css()}>
      <div>
        <div style={{ display: 'flex' }}>
          {registration && (
            <>
              <Label size="large" className={css('time').toString()}>
                {t(`${translationPrefix}.time`, registration)}
              </Label>
              <Button
                className={css('apply').toString()}
                primary
                icon
                labelPosition="right"
                onClick={() => setIsOpen(!isOpen)}
              >
                <Icon name={`chevron circle ${isOpen ? 'up' : 'down'}`} />
                {t(`${translationPrefix}.update-appointment`)}
              </Button>
            </>
          )}
          {!registration && hasSlots && (
            <Button
              className={css('apply').toString()}
              primary
              icon
              labelPosition="right"
              onClick={() => setIsOpen(!isOpen)}
            >
              <Icon name={`chevron circle ${isOpen ? 'up' : 'down'}`} />
              {t(`${translationPrefix}.apply`)}
            </Button>
          )}
        </div>
        {isOpen && (
          <AppointmentsBlock
            {...appointment}
            slotFilters={slotFilters}
            appointmentUserRef={appointmentUserRef}
            extraBookingData={extraBookingData}
          />
        )}
      </div>
      {!hasSlots && !registration && (
        <div className={css('no-slots')}>
          {showContactMeBlock ? (
            <>
              <Header as="h4">
                <Icon name="warning sign" />
                {t(
                  `${translationPrefix}.${
                    userAlreadyRequestedToBeCalled ? 'user-requested-to-be-called' : 'no-free-slots'
                  }`,
                )}
              </Header>
              {!userAlreadyRequestedToBeCalled && (
                <Button primary onClick={handleContactMe}>
                  {t(`${translationPrefix}.send-info`)}
                </Button>
              )}
            </>
          ) : (
            <Header as="h4">
              <Icon name="warning sign" />
              {t(`${translationPrefix}.no-available-slots`)}
            </Header>
          )}
        </div>
      )}
    </div>
  );
}

ApplyOpportunityBlock.defaultProps = {
  appointment: {},
};

ApplyOpportunityBlock.propTypes = {
  appointment: PropTypes.object,
  item: PropTypes.object.isRequired,
};

export default ApplyOpportunityBlock;
