/* eslint-disable @typescript-eslint/ban-types */
import { get } from 'lodash';
import React from 'react';
import cx from 'classnames';
import { Grid, SemanticWIDTHS } from 'semantic-ui-react';
import BlockContainer from '../../BlockContainer';
import { ContainerProps } from '../types';
import './ItemGridBlock.scss';
import { Item } from './types';
import ClassicCircle from './variants/ClassicCircle';
import Classic from './variants/Classic';

type GridProps = {
  columns: SemanticWIDTHS;
  doubling?: boolean;
};

type ItemProps = {
  variant: 'classic-circle';
};

type ItemGridBlockProps = {
  as?: JSX.Element | undefined;
  container?: ContainerProps;
  grid?: GridProps;
  item?: ItemProps;
  items?: Item[];
  className?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (...args: any[]) => any;
};

const itemVariants = {
  'classic-circle': ClassicCircle,
  classic: Classic,
};

const ItemGridBlock = (props: ItemGridBlockProps): JSX.Element => {
  const { as, className, container, item, items, grid, onClick } = props;
  const { variant: itemVariant = 'classic-circle', ...itemProps } = item || {};
  const ItemComponent = get(itemVariants, itemVariant, itemVariants['classic-circle']);
  return (
    <BlockContainer className={cx('block--ItemGrid', className)} type="segment" {...container}>
      <Grid columns={2} {...grid}>
        {(items || []).map((it) => (
          <Grid.Column>
            <ItemComponent as={as} key={it._id} item={it} {...itemProps} onClick={onClick} />
          </Grid.Column>
        ))}
      </Grid>
    </BlockContainer>
  );
};

ItemGridBlock.defaultProps = {
  as: undefined,
  container: {},
  className: '',
  grid: {},
  item: {},
  items: [],
  onClick: undefined,
};

export default ItemGridBlock;
