import React from 'react';
import PropTypes from 'prop-types';
import SponsorsBlock from '../../components/sponsors/SponsorsBlock';

const WorkshopSponsorsBlock = ({ item, field, header }) => {
  const entries = item[field];
  if (!entries || entries.length === 0) return null;

  return <SponsorsBlock header={header} ids={entries} />;
};

WorkshopSponsorsBlock.defaultProps = {
  header: undefined,
  field: 'sponsors',
};

WorkshopSponsorsBlock.propTypes = {
  item: PropTypes.object.isRequired,
  field: PropTypes.string,
  header: PropTypes.string,
};

export default WorkshopSponsorsBlock;
