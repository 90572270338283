import React, { useState } from 'react';
import { Button, Form, Header } from 'semantic-ui-react';
import { getString } from '../../utils';
import { localeFormat } from '../../utils/date';
import { StepContainer } from '../components/Step';
import { useStepContext } from './StepContext';

const translationPrefix = 'appointments.steps';
const t = (key, data) => getString(`${translationPrefix}.${key}`, data);

export const StepConfirmSidebar = () => {
  const { setStep, selectedSlot } = useStepContext();
  return (
    <div>
      <StepContainer label="1">
        <Button className="link" onClick={() => setStep('select-slot')}>
          {t('select-slot.sidebar-label')}
        </Button>
      </StepContainer>
      <StepContainer label="2" top active>
        {t('confirm.sidebar-label')}
        <p style={{ marginTop: '0.5em' }}>
          <Button primary size="tiny" compact>
            {localeFormat(selectedSlot.startDate, 'PPPp')}
          </Button>
        </p>
      </StepContainer>
      <StepContainer label="3" top>
        {t('prepare.sidebar-label')}
      </StepContainer>
    </div>
  );
};
export const StepConfirmContent = () => {
  const { selectedSlot, confirmData, onConfirm } = useStepContext();
  const [data, setData] = useState(confirmData || {});

  function handleConfirm() {
    // TODO: validate
    onConfirm(data);
  }

  return (
    <div className="step__confirm">
      <p>{t('confirm.date', { date: localeFormat(selectedSlot.startDate, 'PPPPp') })}</p>
      <Form onSubmit={handleConfirm}>
        <Header as="h3">{t('confirm.header')}</Header>
        <Form.TextArea
          name="message"
          rows={5}
          onChange={(e, { value }) => setData({ message: value })}
        />
        <Button primary>{t('confirm.button')}</Button>
      </Form>
    </div>
  );
};
