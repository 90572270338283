import { useBoolean } from 'ahooks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon, Modal } from 'semantic-ui-react';
import MenuFilters from './MenuFilters';

const FilterModal = (props) => {
  const [open, { setFalse: setClosed, setTrue: setOpened }] = useBoolean(false);
  const { t } = useTranslation();
  return (
    <Modal
      className="filter--modal"
      onClose={setClosed}
      onOpen={setOpened}
      open={open}
      trigger={
        <Button
          className="filters--button"
          style={{
            width: '100%',
            backgroundColor: 'white',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Icon name="filter" />
          <p className="label" style={{ marginLeft: 10 }}>
            {t('filters.label')}
          </p>
        </Button>
      }
    >
      <Modal.Content style={{ maxHeight: '80vh', overflowY: 'auto' }}>
        <MenuFilters {...props} />
      </Modal.Content>
      <Modal.Actions>
        <Button
          content={t('btn.validate')}
          labelPosition="right"
          icon="checkmark"
          onClick={setClosed}
          positive
        />
      </Modal.Actions>
    </Modal>
  );
};
export default FilterModal;
