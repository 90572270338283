import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { Icon, Segment } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { bem } from '../../core/design/bem';
import Blocks from '../Blocks';
import { replaceValues } from '../../utils/stringUtils';
import CdnImage from '../CdnImage';
import ProgressCircular from '../ProgressCircular';
import ImageIcon from '../ImageIcon';
import { userProptypes } from '../../propTypes';
import OptLink from '../../OptLink';
import ApplyOpportunityBlock from '../ApplyOpportunityBlock';
import HtmlBlock from '../../exhibitors/blocks/HtmlBlock';
import { eventTags } from '../../core/trackers/events';

const translationPrefix = 'components.list-details.details';
const cxList = bem('list-details');
const cxListDetails = bem(cxList('details').toString());
const cxListDetailsHeader = bem(cxListDetails('header').toString());

const Header = (props) => {
  const { config, item } = props;
  const { t } = useTranslation();
  const {
    image: imageField = 'image',
    title: titleTemplate = '{title}',
    subtitle: subtitleTemplate,
    extra: extraTemplate,
    link: linkTemplate,
    externalUrl: externalUrlTemplate,
    percentage: percentageField = 'percentage',
  } = config;
  const image = get(item, imageField);
  const title = replaceValues(titleTemplate, item);
  const subtitle = replaceValues(subtitleTemplate, item);
  const extra = replaceValues(extraTemplate, item);
  const link = replaceValues(linkTemplate, item);
  const externalUrl = replaceValues(externalUrlTemplate, item);
  const percentage = get(item, percentageField);
  return (
    <div className={cxListDetails('header')}>
      {image && image.uri && (
        <CdnImage
          src={image}
          className="image"
          maxHeight={80}
          cdnOptions={{ bg_color: 'transparent' }}
        />
      )}
      <div className="content">
        {title && <h4 className="title">{title}</h4>}
        {subtitle && <p className="subtitle">{subtitle}</p>}
        {extra && <p className="extra">{extra}</p>}
        {(link || externalUrl) && (
          <div className={cxListDetailsHeader('links')}>
            {link && (
              <OptLink
                className="link"
                to={link}
                item={item}
                trackClick={eventTags.LIST_DETAIL_ITEMS_GO_TO_PAGE}
              >
                {t(`${translationPrefix}.link`)}
              </OptLink>
            )}
            {externalUrl && (
              <OptLink
                className="external-url"
                to={externalUrl}
                item={item}
                trackClick={eventTags.LIST_DETAIL_ITEMS_GO_TO_EXTERNAL_PAGE}
              >
                {t(`${translationPrefix}.external-url`)}
              </OptLink>
            )}
          </div>
        )}
      </div>
      {percentage && (
        <div className="percentage">
          <ProgressCircular value={percentage} config={config} />
        </div>
      )}
    </div>
  );
};

Header.propTypes = {
  config: PropTypes.isRequired,
  item: PropTypes.object.isRequired,
};

const Matching = (props) => {
  const { item, fields = [], iconConfig, user } = props;
  const renderField = (field) => {
    const { key, type } = field;
    const value = get(item, key);
    switch (type) {
      case 'list': {
        const { userField } = field;
        if (!Array.isArray(value)) return null;
        const userValue = get(user, userField);
        const formattedValue = value.map((v) => ({ label: v, checked: userValue?.includes(v) }));
        return (
          <div>
            {formattedValue.map(({ label, checked }) => (
              <div>
                <Icon name={checked ? 'check' : 'times'} color={checked ? 'green' : 'red'} />
                {label}
              </div>
            ))}
          </div>
        );
      }
      case 'fields': {
        const { fields: items = [] } = field;
        return (
          <div className="fields">
            {items.map(({ key: fieldKey, userField }) => {
              // eslint-disable-next-line no-shadow
              const value = get(item, fieldKey);
              if (!value) return null;
              const userValue = get(user, userField);
              const checked = value === userValue;
              return (
                <div key={fieldKey}>
                  <Icon name={checked ? 'check' : 'times'} color={checked ? 'green' : 'red'} />
                  {value}
                </div>
              );
            })}
          </div>
        );
      }
      default:
        return null;
    }
  };
  return (
    <div className={cxListDetails('matching')}>
      {fields.map((field) => {
        const { key, icon, label } = field;
        return (
          <div key={key} className="field">
            <ImageIcon icon={icon} {...iconConfig} />
            <p className="label">{label}</p>
            {renderField(field)}
          </div>
        );
      })}
    </div>
  );
};

Matching.defaultProps = {
  fields: [],
  iconConfig: {},
};

Matching.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.object),
  iconConfig: PropTypes.object,
  item: PropTypes.object.isRequired,
  user: PropTypes.shape(userProptypes).isRequired,
};

const components = {
  header: Header,
  matching: Matching,
  html: HtmlBlock,
  'apply-opportunity': ApplyOpportunityBlock,
};

function ConnectedDetails(props) {
  const { item, config, user } = props;
  const { details: detailConfig = {} } = config;
  const { blocks = [] } = detailConfig;
  return (
    <Segment className={cxListDetails().toString()}>
      <Blocks
        blocks={blocks}
        sharedProps={{
          item,
          user,
          config: detailConfig,
        }}
        blockComponents={components}
      />
    </Segment>
  );
}

ConnectedDetails.defaultProps = {
  config: {},
  user: undefined,
};

ConnectedDetails.propTypes = {
  config: PropTypes.object,
  item: PropTypes.object.isRequired,
  user: PropTypes.shape(userProptypes),
};

const Details = connect((state) => ({
  user: state.user.user,
}))(ConnectedDetails);

export default Details;
